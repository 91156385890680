.categories-link,
.categories-checkbox {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #f0f2f5;
}
.categories-link {
    height: 500px;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.categories-link::-webkit-scrollbar {
    display: none;
}
.categories-link button {
    background-color: inherit;
    border: none;
    align-self: flex-start;
    text-transform: lowercase !important;
    cursor: pointer;
}
.categories-link button:first-line {
    text-transform: capitalize !important;
}
.categories-link button,
.categories-link h3,
.price-slider h3,
.categories-checkbox h3,
.categories-checkbox label {
    color: #103178;
    text-decoration: none;
    font-weight: 600 !important;
    font-size: 1.1rem;
    transition: 1s;
    font-family: "Source Sans Pro", sans-serif;
}
.activeLink {
    color: red !important;
}
.categories-link button:hover {
    color: #ff9923;
}
.price-slider {
    padding: 2rem 0 4rem 0;
    border-bottom: 1px solid #f0f2f5;
}
.categories-checkbox {
    padding: 2rem 0;
}
.check-box {
    display: flex;
    gap: 0.6rem;
    align-items: center;
}
.categories-checkbox label,
.check-box input {
    cursor: pointer;
}
.check-box input {
    transform: scale(1.25);
}
.star-rating {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    margin-bottom: 0.2rem;
}
.star-rating p {
    color: #103178;
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    font-weight: 550;
}
@media screen and (max-width: 800px) and (min-width: 550px) {
    .categories-link button,
    .categories-link h3,
    .price-slider h3,
    .categories-checkbox h3,
    .categories-checkbox label {
        font-size: 0.8rem;
    }
}
