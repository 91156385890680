.Search-Container {
    padding: 1.3rem 0rem 0rem 6.5rem;
    border-bottom: 1px solid #f0f2f5;
    display: grid;
    gap: 1rem;
    grid-template-columns: 15% 10% 45% 20%;

    align-items: center;
}
.Search-Container img {
    width: 100px;
    cursor: pointer;
}
.search-input {
    justify-self: center;
    width: 100%;
    position: relative;
}
.acc-settings-btn > img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
}
.search-input input {
    width: 90%;
    height: 3rem;
    text-indent: 3rem;
    border-radius: 2rem;
    border: none;
    background-color: #f0f2f5;
    color: #5b6c8f;
    font-size: 1.1rem;
    outline: none;
    /* font-weight: 550; */
}
.search-icon {
    position: absolute;
    top: 15px;
    left: 15px;
    color: #5b6c8f;
}
.close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    border: none;
    outline: none;
    background-color: inherit;
}
.close-icon {
    color: red;
}
.user-details {
    justify-self: right;
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.user-details button {
    border: white;
    cursor: pointer;
    position: relative;
    background-color: inherit;
}
.user-details button img {
    width: 30px;
}
.user-details button p {
    position: absolute;
    top: -0.3rem;
    right: -0.5rem;
    z-index: 10;
    width: 1.2rem;
    height: 1.2rem;
    background-color: #103178;
    color: white;
    border-radius: 50%;
    font-size: 0.8rem;
    display: grid;
    align-items: center;
    justify-items: center;
    margin: 0;
    font-family: "Roboto", sans-serif !important;
    font-weight: bold;
    /* padding: 0.5rem; */
}
.search-input-wrapper {
    position: relative;
}

.search-results {
    position: absolute;
    top: 3.1rem;
    word-wrap: break-word;
    white-space: normal;
    width: 90%;
    background-color: white;
    left: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
    height: fit-content;
    min-height: 150px;
    max-height: 300px;
    overflow: scroll;
    overflow-x: hidden;
    box-shadow: 2px 6px 21px -2px rgba(0, 0, 0, 0.75);
    background-color: white;
    border: 1px solid rgba(229, 231, 235, 1);
}
.search-results p {
    padding: 1rem 0 0 1rem;
}
.search-results button {
    border: none;
    background-color: inherit;
    cursor: pointer;
    width: 100%;
    align-self: flex-start;
    text-align: left;
    padding: 0.5rem 1rem;
    font-family: "Roboto", sans-serif;
    font-weight: 550;
}
.search-results button:hover {
    background-color: lightgrey;
}
.Search-Container select {
    width: 100%;
    height: 2.5rem;
    text-indent: 0.5rem;
    outline: none;
}
.account-settings {
    position: relative;
}
.account-settings button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.setting-btns {
    display: grid;
    gap: 1rem;
    background-color: white;
    z-index: 1000;
    width: 8.6rem;
    padding: 1rem 0.6rem;
    box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%),
        0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%);
    position: absolute;
    top: 2rem;
    left: 0.5rem;
    border: 1px solid lightgrey;
}
.setting-btns button {
    background-color: inherit;
    border: none;
    font-size: 0.9rem;
}
.setting-btns a,
.setting-btns button {
    font-size: 1rem;
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-family: "Source Sans Pro", sans-serif;
}
.setting-btns a:hover,
.setting-btns button:hover {
    color: #ff9923;
}
@media screen and (max-width: 1166px) {
    .Search-Container {
        padding: 1rem;
        grid-template-columns: 1fr 20%;
        align-items: initial;
    }
    .Search-Container select {
        grid-column: 2/3;
        grid-row: 2/3;
        align-items: center;
        /* height: 100%; */
    }
    .user-details {
        grid-column: 2/3;
        grid-row: 1/2;
    }
    .search-input-wrapper {
        grid-row: 2/3;
        grid-column: 1/2;
    }
    /* .account-settings {
        padding-right: 3rem;
    } */
}
.icon {
    font-size: 1.8rem;
}
.acc-settings-btn h3 {
    white-space: nowrap;
    font-size: 0.9rem;
    font-family: "Roboto", sans-serif;
    font-weight: 650 !important;
}
.drop-down {
    display: none;
}
@media screen and (max-width: 767px) {
    .setting-btns {
        width: 6rem;
    }
    .setting-btns a,
    .setting-btns button {
        font-size: 0.9rem;
    }
    .drop-down {
        display: grid;
        /* margin-right: 1rem; */
    }
    .menu-btns {
        left: 0;
        right: 0;
        position: absolute;
        display: grid;
        align-items: center;
        justify-items: left;
        padding: 1rem;
        background-color: lightblue;
        /* -webkit-box-shadow: -1px 6px 5px -1px rgb(0 0 0 / 0.2); */
        /* -moz-box-shadow: -1px 6px 5px -1px rgb(0 0 0 / 0.2); */
        /* box-shadow: -1px 6px 5px -1px rgb(0 0 0 / 0.2); */
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        justify-items: center;

        text-align: left;
        gap: 1.2rem;
        top: 6.5rem;
        z-index: 10;
        animation: growDown 300ms ease-in-out forwards;
        transform-origin: top center;
    }
    @keyframes growDown {
        0% {
            transform: scaleY(0);
        }
        80% {
            transform: scaleY(1.1);
        }
        100% {
            transform: scaleY(1);
        }
    }
    .menu-btns a {
        color: white;
        /* color: #103178; */
        text-decoration: none;
        font-size: 1.2rem;
        font-weight: bold;
        text-align: left;
        font-family: "Roboto", sans-serif;
    }
}
@media screen and (max-width: 500px) {
    .Search-Container {
        padding-right: 0;
    }
    .Search-Container img {
        width: 60px;
    }
    .menu-btns {
        top: 5.5rem;
    }
    .user-details {
        gap: 0;
        row-gap: 1.2rem;
        justify-self: right;
    }
    .user-details button {
        margin-right: 1rem;
    }
    .icon {
        font-size: 1.2rem;
    }
    .user-details button p {
        width: 0.9rem;
        height: 0.9rem;
        font-size: 0.7rem;
    }
    .search-input-wrapper {
        grid-row: 2/3;
        grid-column: 1/-1;
    }
    .Search-Container select {
        grid-column: 1/-1;
        grid-row: 3/4;
        width: 90%;
    }
}
