.sidenavwrapper {
    /* z-index: 100; */
    background: #0e549d;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
    position: fixed;
    /* min-height: 100vh; */
    height: 100%;
    width: 280px;
    transition: 1s;
    overflow: scroll;
    -moz-transition: all 200ms ease-in;
    -webkit-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
    transition: all 200ms ease-in;
}
.sidenavbar {
    display: flex;
    flex-direction: column;
    gap: 0rem;
    transition: 1s;
    height: 100%;
    /* position: relative; */
}
.logo {
    /* align-self: center; */
    display: flex;
    align-items: flex-end;
    gap: 0.3rem;
    outline: none;
    border: none;
    background-color: white;
    font-size: 1.5rem;
    font-weight: 600;
    cursor: pointer;
    opacity: 0.8;
    margin-left: 1.3rem;
    padding: 1rem;
}
.logo-icon {
    align-self: flex-start;
}
.Navlinks {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    padding-top: 2rem;
}
.Navlinks a {
    display: flex;
    text-decoration: none;
    align-items: center;
    gap: 1rem;
    /* font-weight: 700; */
    font-size: 1.1rem;

    font-family: "Source Sans Pro", sans-serif;
    color: white;
}

.nav-inactive {
    padding: 0rem 2rem;
    background-color: inherit;
}
.nav-active {
    background: #f58634;
    padding: 0.7rem 2rem;
}
.Navlinks a:hover {
    color: #f58634;
}
.nav-active:hover {
    color: white !important;
}
.logout button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    color: white;
    background-color: inherit;
    border: none;
    font-size: 1rem;
    padding: 1.3rem 2rem;
    cursor: pointer;
    margin-right: 1rem;
    border-radius: 10px;
}
.logout button:hover {
    color: #f58634;
}

.menu-icon {
    display: none;
    align-self: flex-start;
    font-size: 2rem;
    cursor: pointer;
    margin: 1rem 1rem 1rem 2rem;
}
.nav-icon {
    font-size: 1.2rem;
}
.sidenavwrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidenavwrapper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

@media screen and (max-width: 786px) {
    .drop-back {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        /* z-index: 20; */
    }
    .menu-icon {
        display: block;
    }

    .sidenavwrapper {
        /* width: 100%; */

        width: fit-content;
        height: 100%;
        /* z-index: 10000; */
        gap: 3rem;
    }
    .sidenavbar > button,
    .Navlinks a span,
    .logout span {
        display: none;
    }

    .logout button {
        display: flex;
        text-decoration: none;
        align-items: center;
        font-size: 0.8rem;
        margin: 0;
        padding: 0rem;
        color: white;
        background-color: inherit;
    }
    .wrapper-show {
        background-color: #0e549d;
        box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
        /* min-height: 100vh; */
        height: 100%;
        width: 280px;
        transition: 1s;
        z-index: 10;
    }
    .show > button,
    .show .Navlinks a span,
    .show .logout span {
        display: block;
    }

    .Navlinks a {
        padding: 0.6rem 1rem;
    }
    .show .Navlinks a {
        padding: 0.6rem 2rem;
    }

    .nav-icon-logout {
        display: flex;
        text-decoration: none;
        align-items: center;
        font-size: 1rem;
        padding: 0.6rem 2rem;
        color: #707275;
    }

    .menu-icon {
        font-size: 1.5rem;
        margin-left: 1.1rem;
    }
    .logout {
        align-items: flex-start;
        margin: 1rem;
    }
    .logout button:hover {
        background-color: unset;
    }
    .show .logout {
        display: flex;
        align-items: flex-end;
    }
    .show .logout button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        color: white;
        background-color: #0e9f6e;
        border: none;
        font-size: 1rem;
        padding: 0.8rem 3rem;
        cursor: pointer;
        margin-right: 1rem;
        border-radius: 10px;
    }
}
