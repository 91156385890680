.product-Container {
    padding: 2rem 7rem;
    display: grid;
    gap: 3rem;
}

.product-Container > h2 {
    text-align: center;
    color: #103178;

    font-family: "Source Sans Pro", sans-serif;
    font-size: 2.2rem;
}
.slider {
    width: 85vw;
}
.product {
    display: flex;
    flex-direction: column;

    color: #103178;
    cursor: pointer;
    position: relative;
    height: 25rem;
    gap: 1rem;
    /* width: 95%; */
    background-color: #f9f9f9;
    max-width: 15rem;
    margin: 0rem;
    /* max-width: fit-content; */
}
.generic {
    font-size: 13px !important;
    color: #8b8b8b !important;
    color: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.company {
    margin: 0;
    /* line-height: 0.5rem; */
    color: #8b8b8b;
    font-size: 0.85rem;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    font-family: "Roboto", sans-serif;
    /* -webkit-box-orient: vertical; */
}
/* .company span::first-letter {
    color: red;
    text-transform: capitalize;
} */
.blur-text {
    color: transparent;
    text-shadow: 0 0 8px #000;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.cost {
    font-family: "Roboto", sans-serif;
    color: black;
}
.product img {
    justify-self: center;
    align-self: center;
    height: 100%;
    /* max-width: 160px !important; */
    width: 100%;
    object-fit: cover;
}
.product-desc {
    /* padding: 0 1rem; */
    /* background-color: white; */
    display: grid;
    gap: 0.2rem;
    height: 100%;
    padding: 0 1rem;
    text-transform: lowercase;
}
.product-desc h3 {
    /* text-overflow: ellipsis; */
    text-transform: uppercase;
}
.add-items {
    align-self: end;
    align-items: end;
    display: grid;
    align-items: end;
    gap: 1rem;
    padding-bottom: 1rem;
}
.add-items select {
    height: 2.5rem;
}
.mrp {
    display: flex;
    gap: 1rem;
}
.mrp p {
    font-family: "Roboto", sans-serif;
    font-size: 0.9rem;
    margin: 0;
    color: black;
    font-weight: 650;
}
.mrp del {
    color: rgb(155, 153, 153) !important;
}
.save {
    color: green !important;
}
.product h3 {
    /* font-weight: 500; */
    font-size: 0.9rem;
    height: 1rem;
    /* font-family: ; */
    color: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
}

/* .product p {
    font-size: 1rem;
    font-family: inherit;
    color: inherit;
    font-weight: 550;
} */
.product-action-btns {
    position: absolute;
    display: grid;
    top: 1.7rem;
    right: 1rem;
    gap: 1.2rem;
    /* visibility: hidden; */
    display: none;
    animation: appear 0.5s ease;
    transition: 1s;
}
.product-action-btns button {
    background-color: #f0f2f5;
    border: none;
    display: grid;
    align-items: center;
    justify-items: center;
    width: fit-content;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    cursor: pointer;
    transition: 1s;
}
.product-action-btns button:hover {
    background-color: #ff9923;
}

.button-one {
    position: relative;
    display: inline-block;
}

.add-items p {
    color: red;
    width: 100%;
    padding: 0.5rem 0;
    text-align: center;
    background-color: rgba(222, 56, 56, 0.2);
}
.add-items button {
    cursor: pointer;
    color: white;
    background-color: #ff9923;
    border: none;
    height: 2.5rem;
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0.01rem;
}
.add-items button:disabled {
    background-color: lightgrey;
    color: grey;
}
/* Tooltip text */
.button-one .tooltiptext {
    visibility: hidden;
    width: 70px;
    background-color: white;
    border: 1px solid #103178;
    color: black;
    text-align: center;
    font-size: 0.8rem;
    padding: 5px;
    border-radius: 6px;
    /* Position the tooltip text - see examples below! */
    position: absolute;
    /* bottom: -2rem; */
    top: 0rem;
    left: -90px;
    z-index: 10;
}

/* Show the tooltip text when you mouse over the tooltip container */
.button-one:hover .tooltiptext {
    visibility: visible;
}
.gift {
    background-image: url("../../../Assets/giftbg.jpg");
    height: 5rem;
    background-position: 50% 50% !important;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
}
.gift h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #12a05c;
    font-size: 1.4rem;

    font-family: "Source Sans Pro", sans-serif;
    font-weight: 450;
    line-height: 30px;
}
.gift h3 span {
    font-weight: 550;
}
.gift button {
    color: white;
    background-color: #12a05c;
    cursor: pointer;
    border: none;
    width: fit-content;
    padding: 0.6rem 1.8rem;
    border-radius: 1rem;
    font-size: 1rem;
    /* font-family: sans-serif; */
    font-weight: 550;
    transition: 0.6s;
}
.gift button:hover {
    color: #12a05c;
    background-color: white;
    border: 1px solid #12a05c;
}
.gift-icon {
    font-size: 1.4rem;
}
@keyframes appear {
    from {
        opacity: 0;
        display: none;
        right: 0rem;
    }

    to {
        opacity: 1;
        display: grid;
        right: 1rem;
    }
}
@media screen and (max-width: 1166px) {
    .product-Container {
        padding: 2rem;
    }
}
@media screen and (max-width: 767px) {
    .product-Container-slider {
        padding: 1.3rem;
    }
    .product-Container > h2 {
        font-size: 1.2rem;
    }
    .gift {
        display: grid;
        align-items: center;
        height: 10rem;
    }
    .gift h3 {
        font-size: 1rem;
    }
    .gift h3 {
        display: block;
    }
    .gift-icon {
        font-size: 1.4rem;
    }
    .product {
        max-width: 95%;
    }
}
.product:hover .product-action-btns {
    /* visibility: show; */
    display: grid;
}
.banner-slide {
    position: relative;
}
.banner-slide button {
    position: absolute;
    bottom: 2rem;
    left: 3rem;
    z-index: 100;
    width: 10rem;
    background-color: white;
    color: #103178;
    padding: 0.8rem 2rem;
    border-radius: 2rem;
    font-size: 1.2rem;
    outline: none;
    border: none;
    transition: 0.6s;
    cursor: pointer;
    font-weight: 500;
}
.banner-slide img {
    width: 100%;
    height: 400px;
    object-fit: cover;
}
.generic-name {
    /* line-height: 1rem; Sets line height to 1.5 times text size */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.product-image {
    height: 10rem;
}
