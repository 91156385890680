.address-wrapper {
    display: grid;
    gap: 1.2rem;
    padding: 1rem 3rem;
}
.address-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.address-header h3 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    /* font-weight: 700; */
    font-size: 1.6rem;
    line-height: 72px;

    color: #103178;
}
.address-header button {
    height: fit-content;
    width: fit-content;
    padding: 0.8rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    background-color: #103178;
    color: white;
    border: none;
    border-radius: 10px;

    font-family: "Roboto", sans-serif;
}
.address {
    display: grid;
    position: relative;
    gap: 0.5rem;
    background: #ffffff;
    padding: 1rem 2rem;
    border-radius: 10px;
}
.address p {
    margin: 0;
}
.address h3 {
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0.01rem;
    font-style: normal;

    font-size: 1.6rem;
    letter-spacing: 0.01em;
    color: #103178;
    font-weight: 600;
}
.address header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
}
.address .edit-address {
    display: none;
    align-items: center;
    gap: 1rem;
}
.address:hover .edit-address {
    display: flex;
}
.edit-address button {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    width: fit-content;
    height: fit-content;
    padding: 0.3rem 0.5rem;
    color: #f58634;
    border: 1px solid #f58634;
    font-size: 1rem;
    cursor: pointer;
    background-color: inherit;
    font-family: "Roboto", sans-serif;
}
.address p {
    font-family: "Roboto", sans-serif;
    font-weight: 550;
    color: #103178;
    font-size: 1.3rem;
}
.general-address {
    position: absolute;
    right: 10px;
    top: 10px;
}
.emptyCart-wrapper {
    /* width: 500px; */
    padding: 1.3rem 9rem 0 9rem;
}
.emptyCart {
    background-color: white;
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 0 0 1rem 0;
    /* box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75); */
}
.emptyCart h1 {
    justify-self: left;
    border: none;
}
.emptyCart h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.3rem;
}
.emptyCart p {
    margin: 0.4rem 0 !important;
}
.emptyCart img {
    width: 300px;
}
.emptyCart button {
    margin: 0.6rem 0;
    background-color: #103178;
    color: white;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 0.5rem 2rem;
}
@media screen and (max-width: 767px) {
    .emptyCart-wrapper {
        padding: 1rem;
    }
    .address-wrapper {
        padding: 0.5rem;
    }
    .address-header {
        display: grid;
        /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
    }
    .address {
        padding: 1rem;
    }
    .address-header button {
        padding: 0.5rem 1rem;
    }
    .address .edit-address {
        display: flex;
    }
    .address header {
        display: grid;
        height: unset;
        gap: 0.5rem;
    }
    .edit-address {
        justify-self: right !important;
    }
    .edit-address button {
        padding: 0.3rem;
        font-size: 0.6rem;
    }
    .general-address {
        position: unset;
    }
    .address-header h3 {
        font-size: 1.6rem;
    }
}

/* -----------modal --------------- */
.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    animation: linear normal forwards;
    animation-name: run;
    animation-duration: 100ms;
    display: flex;
    justify-content: center;
    z-index: 1000;
    transition: 100ms;
}
.modal-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    opacity: 1;
    /* max-width: 700px; */
    overflow: scroll;
    min-width: 700px;
    margin: 4rem 0;
    width: fit-content;
    background: #ffffff;
    /* -ms-overflow-style: none; 
    scrollbar-width: none; */

    position: relative;
}
/* .modal-content::-webkit-scrollbar {
    display: none; 
} */
/* .modal-content: */
.modal-content-header {
    padding: 1rem 1.5rem;
    font-weight: 500;
    background-color: #f9fafb;
    font-family: "Source Sans Pro", sans-serif;
    display: flex;
    justify-content: space-between;
}
.modal-content-header h3 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;

    font-size: 1.5rem;
    letter-spacing: 0.01em;
    color: #103178;
    font-weight: 600;
}
.modal-content-header p {
    font-size: 0.9rem;
}
.close-btn {
    align-self: center;
    background-color: white;
    border: 1px solid white;
    outline: none;
    color: red !important;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 1);
}
.add-items {
    display: grid;
    /* height: 100%; */
    gap: 3rem;
    padding: 1rem;
}
.add-items-forms {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3rem 0;
    height: 100%;
}
.add-item {
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 0.5rem;
    width: 100%;
}
.add-item .upload-icon {
    color: #0e9f6e;
    font-size: 2rem;
}
.add-item h5 {
    font-size: 14px;
    margin: 0;
    padding: 0;
    font-family: "Source Sans Pro", sans-serif;
}
.add-item p {
    color: #9e9e9e;
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
}
.item-image-upload label,
.item-tag-input label {
    font-family: "Source Sans Pro", sans-serif;
    color: #24262d;
    font-size: 14px;
    font-weight: 500;
}
.item-image-upload {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 0 1rem;
}
.upload-image {
    border: 2px dashed #d5d6d7;
    width: 70%;
    height: 9rem;
    display: grid;
    align-items: center;
    cursor: pointer;
}
.item-image-upload select,
.item-image-upload input {
    height: 3rem;
    text-indent: 1rem;
    outline: none;
    width: 100%;
    height: 2.5rem;
    font-size: 1rem;
    text-indent: 1rem;
    border-radius: 10px;
    background-color: rgba(229, 231, 235, 0.6);
    outline: none;

    border: 1px solid rgba(229, 231, 235, 1);
}
.item-image-upload textarea {
    text-indent: 1rem;
    outline: none;
    width: 100%;
    padding: 0.5rem 0;
    font-size: 1rem;
    text-indent: 1rem;
    border-radius: 10px;
    background-color: rgba(229, 231, 235, 0.6);
    outline: none;
    resize: none;
    border: 1px solid rgba(229, 231, 235, 1);
}
.item-image-upload select:focus,
.item-image-upload input:focus,
.item-image-upload textarea:focus {
    background-color: white;
}
.error {
    color: rgba(249, 128, 128, 1);
    font-size: 1rem;
    font-weight: 1rem;
    padding-bottom: 0;
    margin-bottom: 0;
}
.item-image-upload .select {
    width: 70%;
}
.item-tag-input {
    display: flex;
    justify-content: space-between;
    margin: 0 1rem;
}
.item-tag {
    width: 69%;
    /* padding: 0.4rem 1rem; */
    border-radius: 10px;
    min-height: 3rem;
    border: 1px solid rgba(229, 231, 235, 1);
}
.item-tag input {
    text-indent: 1rem;
}
.add-btn {
    margin: 0 1rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}
.add-btn button {
    border: 1px solid grey;
    /* padding: 1rem; */
    width: 100%;
    height: 2.5rem;
    border-radius: 5px;
    color: red;
    cursor: pointer;
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    font-weight: 550;
}
.add-btn .cancel:hover {
    background-color: red;
    color: white !important;
    border: 1px solid red;
}
.add-category-btn {
    cursor: pointer;
    background-color: #103178;
    border: 1px solid #103178;
    color: white !important;
}
.add-category-btn:hover {
    background-color: white;
    color: #103178 !important;
    border: 1px solid #103178;
}
/* @keyframes run {
    0% {
        right: -10rem;
    }
    100% {
        right: 0rem;
    }
} */
@media screen and (max-width: 767px) {
    .modal-content {
        width: 85%;
        min-width: 80%;
    }
    .modal-content-header p {
        font-size: 0.8rem;
    }
    .item-image-upload {
        gap: 0.6rem;
        flex-direction: column;
    }
    .upload-image {
        width: 100%;
    }
    .item-image-upload label,
    .item-tag-input label {
        font-size: 17px;
    }
    .item-image-upload .select {
        width: 100%;
    }
    .add-items {
        gap: 1rem;
    }
    .item-tag-input {
        gap: 1rem;
        flex-direction: column;
    }
    .item-tag {
        width: 100%;
    }
}
