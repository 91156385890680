.shop-wrapper {
    padding: 0.5rem 4rem 0.5rem 7rem;
    display: grid;
    gap: 2rem;
    border-top: 1px solid #f0f2f5;
}
.links {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.links a {
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
}
.links p {
    font-family: "Roboto", sans-serif;
    margin: 0;
    padding: 0;
}
.home-link {
    color: #5b6c8f;
}
.shop {
    color: #103178;
    font-weight: bold;
}
.header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
.header > h2 {
    font-family: "Source Sans Pro", sans-serif;
    color: #103178;
    font-size: 2rem;
}
.header > h2 sup {
    font-size: 20px;
    font-weight: 300;
}
.products {
    display: grid;
    grid-template-columns: 25% 70%;
    gap: 1rem;
}
.product-right-side {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}
.sort-wrapper {
    background-color: #f0f2f5;
    height: 4rem;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 6rem;
    padding: 0 1rem;
}
.offer-product,
.sort p {
    display: flex;
    gap: 0.6rem;
    align-items: center;
}
.offer-product label,
.sort p {
    color: #103178;
    text-decoration: none;
    font-weight: 600;
    font-size: 1rem;
    transition: 1s;
}
.sort p {
    cursor: pointer;
}
.offer-product input {
    cursor: pointer;
}
.offer-product input {
    transform: scale(1.25);
}
.sort-option-wrapper {
    height: 100%;
    position: relative;
}
.sort-option-wrapper p {
    height: 4rem;
    font-family: "Roboto", sans-serif;
}
.sort-show,
.sort {
    justify-self: right;
    display: flex;
    gap: 2rem;
    align-items: center;
}
.sort-show {
    padding-right: 3rem;
}
.sort label {
    color: #5b6c8f;
    font-size: 1.1rem;
    font-family: "Roboto", sans-serif;
}
.sort-options {
    position: absolute;
    width: 7rem;
    background-color: white !important;
    display: grid;
    justify-items: left;
    align-items: center;
    gap: 0.4rem;
    top: 4rem;
    -webkit-box-shadow: -1px 4px 5px 1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: -1px 4px 5px 1px rgba(0, 0, 0, 0.75);
    box-shadow: -1px 4px 5px 1px rgba(0, 0, 0, 0.75);
    border-radius: 5px;
    z-index: 100;
    /* display: none; */
}
.sort-options button {
    /* padding: 0.3rem 1rem; */
    color: black;
    text-align: left;
    padding: 0.2rem 0.5rem;
    background-color: white;
    border: none;
    outline: none;
    cursor: pointer;
    width: 100%;
}
.shop-heading {
    display: none;
}
.sort-options button:hover {
    background-color: lightgrey;
}
.emptyCart-wrapper {
    /* width: 500px; */
    padding: 1.3rem 9rem 0 9rem;
    margin-right: 3rem;
}
.emptyCart {
    background-color: white;
    display: grid;
    align-items: center;
    justify-items: center;

    /* box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75); */
}
.emptyCart h1 {
    justify-self: left;
    border: none;
}
.emptyCart h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.3rem;
}
.emptyCart p {
    margin: 0.4rem 0 !important;
    font-family: "Roboto", sans-serif;
    font-weight: 550;
}
.emptyCart img {
    width: 300px;
    /* width: 100%; */
}
.emptyCart button {
    margin: 0.6rem 0;
    background-color: #103178;
    color: white;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    /* padding: 0.5rem 2rem; */
    font-family: "Roboto", sans-serif;
}
@media screen and (max-width: 1166px) {
    .shop-wrapper {
        padding: 1rem;
    }
    /* .products {
        grid-template-columns: 1fr;
    } */
}
@media screen and (max-width: 767px) {
    .offer-product {
        display: none;
    }
    .sort-wrapper {
        grid-template-columns: 1fr;
        justify-items: center;
    }
    .sort-show {
        padding-right: 0;
    }
    .header > h2 {
        font-size: 1.2rem;
    }
}
@media screen and (max-width: 550px) {
    .products {
        grid-template-columns: 1fr;
    }
    .header h2:nth-child(2) {
        display: none;
    }
    .shop-heading {
        display: block;
    }
    .shop-heading h2 {
        font-family: "Source Sans Pro", sans-serif;
        color: #103178;
        font-size: 1.4rem;
    }
    .emptyCart-wrapper {
        margin-right: 0rem;
    }
    .emptyCart img {
        width: 100%;
        max-width: 300px;
    }
}
@media screen and (max-width: 600px) {
    .emptyCart-wrapper {
        padding: 1rem;
    }
}
