.delivery {
    display: grid;
    background-color: #f8f9fa;
    align-items: center;
    padding: 2rem;
    gap: 1.2rem;
}
.delivery .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: "Source Sans Pro", sans-serif;
    color: #103178;
    gap: 0.4rem;
    /* font-size: 1.2rem; */
}
.delivery .content p {
    font-size: 0.8rem;
    font-family: "Roboto", sans-serif;
    line-height: 1.2rem;
    font-weight: 600;
}
.payment {
    background-color: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    border-top: 1px solid lightgrey;
}
.payment img {
    width: 100%;
}
.gift {
    background-image: url("../../../Assets/giftbox.jpg");
    height: 16rem;
    background-position: 50% 50% !important;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    margin-top: 0.5rem;
}
.gift h3 {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    color: #12a05c;
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    font-weight: 450;
    line-height: 30px;
}
.gift h3 span {
    font-weight: 550;
}
.gift-icon {
    font-size: 1.4rem;
    margin-bottom: 0.3rem;
}
.related-products {
    margin-top: 1rem;
}
.related-products h3 {
    color: #103178;
    font-size: 1.1rem;
    font-family: "Source Sans Pro", sans-serif;
    margin: 0.5rem 0;
}
