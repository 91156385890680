.success-wrapper {
    display: grid;
    align-items: center;
    justify-items: center;
}
.success-wrapper img {
    margin: 4rem 0 1rem 0;
    max-width: 350px;
    width: 100%;
}
.success-wrapper h3 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.7rem;
    line-height: 58px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: #198580;
}
.greeting {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    letter-spacing: 0.02em;

    color: #474070;
}
.orderid {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.3rem;
    line-height: 43px;
    letter-spacing: 0.02em;

    color: #0e549d;
}
.success-wrapper button {
    background-color: #198580;
    color: white;
    padding: 0.5rem 1.5rem;
    border: 1px solid #198580;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
}
.success-wrapper button:hover {
    background-color: white;
    color: #198580;
    border: 1px solid #198580;
}
