.login-wrapper {
    /* background-color: rgba(249, 250, 251, 1);
    display: grid;
    justify-items: center;
    align-items: center;
    min-height: 100vh;
    height: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);

    z-index: 10000;
}
.login-subwrapper {
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    border-radius: 10px;
    /* height: 90vh; */
    background-color: white;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
    /* max-height: 700px; */
    height: fit-content;
}

.login-subwrapper img {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    /* height: 90vh; */
    max-height: 670px;
    height: 100%;
}
.login-subwrapper .login-form {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;
}
.close {
    position: absolute;
    top: 0;
    right: 0;
}
.close button {
    cursor: pointer;
    background-color: #f05252;
    border: none;
    color: white;
    padding: 0.5rem;
    font-size: 1.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.resend {
    padding-left: 3rem;
    display: grid;
    gap: 1.3rem;
    justify-items: right;
    width: 90%;
    padding: 1rem 2rem 0 0;
}
.resend button {
    color: #0a245d;
    font-weight: bold;
    background-color: inherit;
    border: none;
    cursor: pointer;
}
.login-form h3 {
    padding-left: 3rem;
    margin-bottom: 1rem;
    color: rgba(36, 38, 45.1);
    font-size: 1.6rem;
    font-weight: 600;
    font-family: inherit;
}
.login-form form {
    padding-left: 3rem;
    display: grid;
    gap: 1.3rem;
    align-items: center;
    width: 90%;
}
.form-control {
    display: grid;
    width: 90%;
    font-family: inherit;
    gap: 0.7rem;
}
.form-control label {
    color: #24262d;
    font-size: 1rem;
}
.form-control input {
    width: 100%;
    height: 2.6rem;
    font-size: 1rem;
    text-indent: 1rem;
    border-radius: 10px;
    background-color: rgba(229, 231, 235, 0.6);
    outline: none;

    border: 1px solid rgba(229, 231, 235, 1);
}
.form-control input:focus {
    background-color: white;
}
.form-control p {
    margin: 0;
}
.error {
    color: rgba(249, 128, 128, 1);
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    width: 100%;
    max-width: 24rem;
}
.login-form form input[type="submit"] {
    width: 92%;
    height: 3rem;
    background-color: rgba(16, 49, 120, 1);
    color: white;
    font-family: "Roboto", sans-serif;
    border: none;
    border-radius: 10px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: 500ms;
}
.login-form form input[type="submit"]:hover {
    background-color: #0a245d;
}
.border {
    padding-bottom: 2rem;
    width: 90%;
}
.social-btns {
    padding-top: 2rem;
    display: grid;
    gap: 1rem;
    width: 90%;
    padding-left: 3rem;
}
.social-btns button {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    width: 90%;
    height: 3rem;
    border: 1px solid rgba(229, 231, 235, 1);
    cursor: pointer;
    border-radius: 10px;

    font-family: "Roboto", sans-serif;
    font-weight: 600;
    font-size: 0.95rem;
    transition: 500ms;
}
.social-btns button p {
    margin: 0;
}
.social-btns .facebook:hover {
    background-color: #1c64f2;
    color: white;
}
.social-btns .google:hover {
    background-color: #f05252;
    color: white;
}
.login-links {
    border-top: 1px solid rgba(229, 231, 235, 1);
    display: grid;
    gap: 0.7rem;
    margin-top: 2rem;
    padding-top: 0.8rem;
    padding-left: 3rem;
}
.login-links button {
    cursor: pointer;
    width: fit-content;
    background-color: inherit;
    border: none;
    color: rgba(16, 40, 120, 1);
    text-decoration: none;
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: capitalize;
    font-family: "Roboto", sans-serif;
}
.login-links a:hover {
    text-decoration: underline;
}
/* @media screen and (min-width: 1500px) {
    .login-subwrapper {
        height: 650px;
    }
} */
.resend button:disabled {
    color: grey;
}
@media screen and (max-width: 768px) {
    .login-wrapper {
        height: 100%;
        padding: 2rem 0;
        align-items: unset;
        padding-top: 5rem;
        /* min-width: 80vw; */
    }
    .login-subwrapper {
        max-height: 100%;
        width: 370px;
        padding: 1rem;
        grid-template-columns: 1fr;
        gap: 0;
        /* min-width: 70%; */
        /* height: 100%; */
    }
    .login-subwrapper img {
        display: none;
        /* height; */
        width: 100%;
        max-height: 350px;
        /* height: 100%; */
        border-bottom-left-radius: 0px;
        border-top-right-radius: 10px;
        object-fit: cover;
        object-position: 50% 50%;
    }
    .login-form form {
        width: 100%;
        padding-left: 0.5rem;
        /* min-width: 330px; */
        width: 100%;
    }

    .social-btns,
    .login-links {
        padding-left: 0.5rem;
        width: 100%;
    }
}
/* --------------sign-up------------ */

.signup-subwrapper {
    position: relative;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    border-radius: 10px;
    /* height: 90vh; */
    background-color: white;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.08);
    /* max-height: 700px; */
    height: fit-content;
}
.signup-subwrapper img {
    width: 530px;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}
.form-control select {
    width: 100%;
    height: 2.6rem;
    font-size: 1rem;
    text-indent: 1rem;
    border-radius: 10px;
    background-color: rgba(229, 231, 235, 0.6);
    outline: none;

    border: 1px solid rgba(229, 231, 235, 1);
}

.signup-subwrapper .login-form h3 {
    padding: 2rem 0 0 3rem;
}
.signup-subwrapper .login-form {
    width: 80% !important;
}
.signup-subwrapper .login-form {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 100%;

    font-family: "Source Sans Pro", sans-serif;
}
.signup-subwrapper .login-links {
    padding: 2rem 0 2rem 3rem;
}
.form-control-terms {
    display: flex;
    align-items: center;
    gap: 0.6rem;
}
.form-control-terms input {
    zoom: 1.3;
}
@media screen and (max-width: 950px) {
    .signup-subwrapper {
        width: 360px;
        padding: 1rem;
        grid-template-columns: 1fr;
        margin: 0;
    }
    .signup-subwrapper img {
        width: 100%;
        max-height: 250px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 10px;
        object-fit: cover;
        object-position: 50% 50%;
    }
    .signup-subwrapper .login-form {
        width: 100% !important;
    }
    .signup-subwrapper .login-links {
        margin-left: 1rem;
        padding: 1rem 0;
    }
}
.forgot-password {
    align-items: center;
}
/* --------------------sign up details --------- */

.details-wrapper {
    display: grid;
    align-items: center;
    padding: 1.3rem 9rem 0rem 9rem;
}
.signupDetails-form form {
    padding: 2rem 0;
    display: grid;
    gap: 1.2rem;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
}
.signupDetails-form form label {
    text-transform: capitalize !important;
    font-weight: 650;
    font-family: "Roboto", sans-serif;
}
.signupDetails-form input[type="submit"] {
    margin: 2rem 1rem 0 0;
    grid-column: 1/-1;
    width: fit-content;
    justify-self: center;
    align-self: center;
    font-size: 1.2rem;
    padding: 0.5rem 2rem;
    background-color: rgba(16, 49, 120, 1);
    color: white;
    font-family: inherit;
    border: none;
    border-radius: 10px;
    font-size: 1.4rem !important;
    cursor: pointer;
    transition: 500ms;
}
.add-item {
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 0.5rem;
    width: 100%;
}
.add-item .upload-icon {
    color: #0e9f6e;
    font-size: 2.3rem;
}
.add-item h5 {
    font-size: 1.1rem;
    margin: 0;
    padding: 0;

    font-family: "Source Sans Pro", sans-serif;
}
.add-item p {
    /* color: #9e9e9e; */
    color: grey;
    font-size: 1rem;
    margin: 0;
    padding: 0;

    font-family: "Roboto", sans-serif;
}
.image-form-control {
    grid-column: 1/-1;
    width: 95%;
}
.uploaded-data {
    display: grid;
    width: 100%;
    gap: 1rem;
}
.upload-image {
    border: 2px dashed #d5d6d7;
    padding: 1rem 0;
    width: 100%;
    height: 9rem;
    display: grid;
    align-items: center;
    cursor: pointer;
}
.uploaded-img {
    justify-self: flex-start;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    row-gap: 1rem;
    width: 100%;
    gap: 1rem;
}
.uploaded-name {
    position: relative;
    width: fit-content;
    /* padding: 1rem; */
}

.uploaded-name button {
    right: -0.5rem;
    position: absolute;
    top: -0.5rem;
    background-color: #be7f00;
    border-radius: 50%;
    border: none;
    outline: none;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.signup-logo {
    display: grid;
    grid-template-columns: 10% 1fr;
    align-items: center;
}
.signupDataHeading {
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 2.3rem;
    font-weight: 550;
    margin-bottom: 1.2rem;
    font-family: "Source Sans Pro", sans-serif;
}
.signup-logo img {
    width: 100px;
    cursor: pointer;
}
@media screen and (max-width: 767px) {
    .details-wrapper {
        padding: 1rem;
    }
}
/*GSTFileURL  */
/* -----------success-wrapper------------ */

.success-wrapper {
    display: grid;
    align-items: center;
    gap: 1rem;
    padding: 1.3rem 9rem 0rem 9rem;
}
.success-wrapper h3 {
    text-align: center;
    font-size: 1.6rem;
    font-family: "Source Sans Pro", sans-serif;
}
.success-wrapper > img {
    margin-top: 2rem;
    justify-self: center;
}
.success-wrapper p {
    color: green;
    font-size: 1.1rem;
    font-weight: 600;
    text-align: center;

    font-family: "Roboto", sans-serif;
}
@media screen and (max-width: 767px) {
    .success-wrapper {
        padding: 2rem;
    }
}
