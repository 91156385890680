.product-wrapper {
    margin: 3rem 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
    align-items: center;
    gap: 2rem;
    justify-items: center;
    padding: 0 5rem;
}
.image-box {
    position: relative;
    overflow: hidden;
    max-width: 370px;
}
.image-box img {
    max-width: 100%;
    transition: all 0.3s;
    display: block;
    width: 100%;
    height: auto;
    transform: scale(1);
}

.image-box:hover img {
    transform: scale(1.1);
}
.product-one,
.product-two,
.product-three {
    position: relative;
}
.productOne-details,
.productTwo-details,
.productThree-details {
    position: absolute;
    display: grid;
    gap: 0.6rem;
    top: 3rem;
    left: 2rem;
    font-family: "Source Sans Pro", sans-serif;
}

.productOne-details h5 {
    background-color: #12a05c;
    width: fit-content;
    font-size: 0.9rem;
    /* letter-spacing: 0.6px; */
    color: white;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 400;
    padding: 0.2rem 1rem;
    border-radius: 2rem;
}
.productThree-details h5 {
    color: #ffc800;
    width: fit-content;
    font-size: 2.6rem;
}
.productThree-details h5 del {
    color: #9babcd;
}
.productOne-details h3,
.productTwo-details h3,
.productThree-details h3 {
    color: #103178;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.7rem;
    line-height: 2.2rem;
    /* font-weight: 500; */
}
.productThree-details h3 {
    color: white;
}
.productOne-details button,
.productTwo-details button,
.productThree-details button {
    cursor: pointer;
    background-color: #103178;
    color: white;
    width: fit-content;
    padding: 0.6rem 2rem;
    border-radius: 1rem;
    border: none;
    outline: none;
    transition: 0.6s;
    font-size: 0.9rem;
    letter-spacing: 1px;
    margin: 0;
}
.productOne-details button:hover,
.productTwo-details button:hover,
.productThree-details button:hover {
    background-color: #fd8d27;
}
.productTwo-details {
    gap: 1rem;
}
.productTwo-details h5 {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 2.5rem;
    line-height: 2.2rem;
    color: #fd8d27;
}
@media screen and (max-width: 767px) {
    .product-wrapper {
        padding: 0;
    }
}
