.pay-wrapper {
    padding: 1.3rem 9rem 0rem 9rem;
}
.pay-wrapper > h3,
.add-address > h3 {
    font-family: "Source Sans Pro", sans-serif;

    color: #103178;
    font-size: 2rem;
    text-align: left;
    margin-bottom: 1rem;
}
.selectAddresses {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}
.address {
    display: grid;
    gap: 0.3rem;
    /* width: 250px; */
}
.address p {
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
}
.address p:first-child {
    font-size: 1.4rem;
    font-weight: bold;
}
.address > button {
    width: fit-content;
    border-top-color: rgb(203, 169, 87);
    border-right-color: rgb(191, 148, 42);
    border-bottom-color: rgb(170, 131, 38);
    border-left-color: rgb(191, 148, 42);
    background: #f0c14b;
    cursor: pointer;
    color: black;
    border: none;
    height: 2.5rem;
    border-radius: 10px;
    padding: 0 2.7rem;
    font-size: 1rem;
    font-weight: 100;
    display: grid;
    align-items: center;
    justify-items: center;
    font-family: "Roboto", sans-serif;
}
.address > button:hover {
    background: #caaf69;
}
.edit-address {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.edit-address button {
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.4rem;
    padding: 0.5rem 0.9rem;
    border: 1px solid lightgrey;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
}
.edit-address button:hover {
    background-color: lightgrey;
}
.add-address {
    margin-top: 1rem;
}
.add-address > h3 {
    text-align: center;
    color: black;
    margin-bottom: 1rem;
    font-size: 1.8rem;
}
.add-items {
    display: grid;
    gap: 0.5rem;
    width: 600px;
}
.item-image-upload {
    display: grid;
    width: 100%;
    gap: 0.5rem;
    font-family: "Roboto", sans-serif;
}
.item-image-upload label {
    font-weight: bold;
}
.item-image-upload input,
.item-image-upload select {
    height: 31px;
    box-shadow: 0 1px 0 rgb(255 255 255 / 50%), 0 1px 0 rgb(0 0 0 / 7%) inset;
    outline: 0;
    width: 100%;
    border-radius: 3px;
    font-family: "Roboto", sans-serif;
    font-size: 1rem;
    text-indent: 10px;
    border: 1px solid #d0d0d0;
}
.item-image-upload input:focus {
    outline: none;
    border-color: #9ecaed;
    box-shadow: 0 0 10px #9ecaed;
}
.add-items button {
    width: fit-content;
    border-top-color: rgb(203, 169, 87);
    border-right-color: rgb(191, 148, 42);
    border-bottom-color: rgb(170, 131, 38);
    border-left-color: rgb(191, 148, 42);
    background: #f0c14b;
    cursor: pointer;
    color: black;
    border: none;
    height: 2.5rem;
    border-radius: 3px;
    padding: 0 1rem;
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 400;
    display: grid;
    justify-self: center;
    margin-bottom: 1rem;
    align-items: center;
    justify-items: center;
    font-family: "Roboto", sans-serif;
}
.error {
    color: red;
}
@media screen and (max-width: 767px) {
    .pay-wrapper {
        padding: 1rem 2rem;
    }
    .add-items {
        width: 90%;
    }
}
/* ------------address-modal--- */

.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    animation: linear normal forwards;
    animation-name: run;
    animation-duration: 100ms;
    display: flex;
    justify-content: center;
    z-index: 1000;
    transition: 100ms;
}
.modal-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    opacity: 1;
    /* max-width: 700px; */
    overflow: scroll;
    min-width: 700px;
    margin: 4rem 0;
    width: fit-content;
    background: #ffffff;
    /* -ms-overflow-style: none; 
    scrollbar-width: none; */

    position: relative;
}

@media screen and (max-width: 767px) {
    .modal-content {
        min-width: 70%;
    }
}
