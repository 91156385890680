.detail-wrapper {
    padding: 0.5rem 9rem;
    display: grid;
    gap: 2rem;
    border-top: 1px solid #f0f2f5;
}
.links {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.blur-text {
    color: transparent;
    filter: blur(7px);
    text-shadow: 0 0 8px #000;
}
.links a {
    text-decoration: none;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
}
.links p {
    margin: 0;
    padding: 0;
}
.home-link {
    color: #5b6c8f;
}
.shop {
    color: #103178;
    font-weight: bold;
}
.details-item {
    display: grid;
    grid-template-columns: 79% 20%;
    gap: 1.4rem;
}
.item-leftside {
    display: grid;
    grid-template-columns: 60% 40%;
    row-gap: 4rem;
    /* align-items: center; */
}
.details-image {
    display: grid;
    /* justify-self: center; */
    gap: 3rem;
}
.display-image {
    width: 80%;
    max-height: 400px;
}
.quick-display-image {
    width: 100%;
    max-height: 400px;
}
.image-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    gap: 1rem;
}
.image-container {
    cursor: pointer;
    border: 1px solid #103178;
    /* height: 1rem; */
}
.image-items img {
    width: 100%;
    height: 100%;
}
.detail-desc {
    align-self: baseline;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.detail-desc > h3 {
    text-transform: uppercase;
    font-size: 13px;
    padding: 0.4rem 0.5rem;
    border-radius: 1rem;
    color: white;
    font-family: "Source Sans Pro", sans-serif;
    width: fit-content;
    margin: 0;
}
.available {
    background-color: #12a05c;
}
.OutofStock {
    background-color: rgb(159, 72, 72);
}
.detail-desc p {
    margin: 0;
    padding: 0;
    color: #5b6c8f;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
}
.detail-desc > h1,
.price > h1 {
    margin: 0;
    padding: 0;
    color: #103178;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.5rem;
    /* line-height: 2rem; */
}
.mrp {
    display: flex;
    gap: 1rem;
}
.mrp p {
    font-size: 1.1rem;
    margin: 0;
    color: black;
    font-weight: 550;
}
.mrp del {
    color: rgb(155, 153, 153) !important;
}
.save {
    color: green !important;
}
.price h1 span {
    font-size: 1rem;
    color: grey;
}
.user-rating {
    display: flex;
    align-items: center;
    gap: 0.7rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #f0f2f5;
}
.user-rating p {
    margin-top: 0.3rem !important;
    align-self: center;
}
.points {
    padding-bottom: 2rem;
    border-bottom: 1px solid #f0f2f5;
}
.points ul {
    list-style: none;
}
.points ul li {
    color: #103178;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    line-height: 1.5rem;
    font-weight: 500;
    text-align: justify;
}
.points ul li:first-child::before {
    content: "✓ ";
    font-weight: 700;
    color: #00a198;
    /* font-family: fo; */
}
.price {
    display: grid;
    gap: 1rem;
}

.sizes {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.sizes h3,
.quantity > h3 {
    font-family: "Roboto", sans-serif;
    color: #103178;
    font-size: 15px;
    /* font-family: sans-serif; */
}
.sizes button {
    border-radius: 2rem;
    padding: 0.3rem 0.6rem;
    font-size: 14px;
    background-color: white;
    color: #103178;
    cursor: pointer;
    /* font-weight: bold; */
}
.btn-active {
    border: 1px solid #103178;
}
.btn-inactive {
    border: 1px solid #f0f2f5;
}
.user-btns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.3rem;
    width: 100%;
    margin-top: 1rem;
}
.total-item {
    /* background-color: #f0f2f5; */
    padding: 0 1rem;
    height: 3rem;
    /* width: 4rem; */
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.total-item input {
    height: 2.2rem;
    font-size: 1.1rem;
    width: 6rem;
    text-indent: 2rem;
}
.total-item button {
    border: none;
    color: #5b6c8f;
    background-color: inherit;
    cursor: pointer;
}

.total-item p {
    font-size: 1.2rem;
    left: 5.5rem;
    color: black;
}
.user-btns > button {
    background-color: #ff9923;
    color: white;
    border-radius: 2rem;
    padding: 0.5rem 0;
    color: white;
    font-family: "Roboto", sans-serif;
    font-size: 1.3rem;
    border: none;
    cursor: pointer;
}
.user-btns > button:disabled {
    background-color: lightgrey;
}
.wishlist-btns {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
}
.wishlist-btns button {
    color: #103178;
    font-size: 15px;
    border: none;
    background-color: inherit;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
    font-family: "Roboto", sans-serif;
}
.frequent {
    grid-column: 1/3;
}
/* .quick-modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    z-index: 10000 !important;
} */
.quickmodal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border: 1px solid #ccc;
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 4px;
    outline: none;
    padding: 20px;
}
.quick-view-wrapper {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    opacity: 1;
    max-width: 900px;
    /* width: 100%; */
    padding: 2rem 0;
    background: #ffffff;
    position: relative;
    overflow: scroll;
    padding: 1rem;
    z-index: 10 !important;
}
.quick-view-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.quick-view-wrapper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
@media screen and (max-width: 1350px) {
    .details-item {
        grid-template-columns: 1fr;
    }
    .right-side {
        display: none;
    }
}
@media screen and (max-width: 900px) {
    .item-leftside {
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }
}
@media screen and (max-width: 767px) {
    .detail-wrapper {
        padding: 1rem;
    }
    .quick-view-wrapper {
        grid-template-columns: 1fr;
    }
    .quickmodal {
        position: absolute;
        top: 80%;
    }
}
