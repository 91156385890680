p {
    margin: 0 !important;

    /* font-family: "Source Sans Pro", sans-serif; */
}
.navbar-container {
    position: relative;
    /* transition: 1s; */

    /* overflow-x: hidden; */
}
.navbar-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    color: #9babcd;
    padding: 1.3rem 7rem;
    background-color: white;
    padding-bottom: 0.8rem;
    /* font-weight: 800; */
    /* border-bottom: 1px solid #f0f2f5; */
}

.navbar {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    color: #103178;
    font-size: 1rem;

    font-family: "Source Sans Pro", sans-serif;
    font-weight: 550;
}
.navbar-item {
    transition: 0.5s;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    color: inherit;
    cursor: pointer;
    /* margin-bottom: 0.1rem; */
    font-family: inherit;
}

.navbar-item p {
    font-family: inherit;
    font-size: inherit;
}
.navbar > button {
    font-weight: inherit;
    border: none;
    background-color: inherit;
    color: inherit;
    font-size: inherit;
    cursor: pointer;
    font-family: inherit;
    transition: 0.5s;
}
.navbar a {
    font-family: "Source Sans Pro", sans-serif;
    color: inherit;
    font-size: inherit;
    text-decoration: none;
    transition: 0.5s;
    font-weight: 600 !important;
}
.nav-inactive {
    cursor: pointer;
}
.navbar-item:hover,
.navbar a:hover,
.nav-inactive:hover,
.navbar > button:hover,
.products-col a:hover {
    color: #ff9923;
}
.active {
    color: #ff9923 !important;
}
.sub-menu {
    position: absolute;
    padding: 1.4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: white;
    width: 100%;
}
.products-col {
    display: grid;

    gap: 1.3rem;
}
.products {
    gap: 8rem;
}
.products-col a {
    color: #103178;
    font-size: 1.3rem;

    font-family: "Source Sans Pro", sans-serif;
    text-decoration: none;
    transition: 0.5rem;
    font-size: 1rem;
    font-weight: 550;
    transition: 0.5s;
}
.products-col h3 {
    padding-bottom: 0.5rem;
    color: #103178;
    font-size: 1.5rem;
    font-weight: 650;
}
.demo-product {
    position: relative;
    transform: scale(1);
}
.demo-product img {
    width: 100%;
    border-radius: 5px;
    max-width: 100%;
    transition: all 0.3s;
    display: block;
    width: 100%;
    height: auto;
    transform: scale(1);
    /* transform: scale(1.1); */
}
.image-box {
    overflow: hidden;
}
.demo-product img:hover {
    transform: scale(1.1);
    border-radius: 5px;
}
.product-detail {
    position: absolute;
    top: 3rem;
    left: 1rem;
    display: grid;
    gap: 0.5rem;

    font-family: "Source Sans Pro", sans-serif;
}

.product-detail h4 {
    background-color: #12a05c;
    font-size: 0.9rem;
    color: white;
    width: fit-content;
    font-family: inherit;
    border-radius: 1rem;
    padding: 0.2rem 0.7rem;
}
.product-detail p {
    margin: 0;
    padding: 0;
    color: #103178;
    font-family: inherit;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 600;
    font-weight: 550;
}
.product-detail button {
    cursor: pointer;
    background-color: #103178;
    color: white;
    width: fit-content;
    padding: 0.6rem 1rem;
    border-radius: 1rem;
    border: none;
    outline: none;
    transition: 0.6s;
    font-size: 0.8rem;
    letter-spacing: 1px;
    margin: 0;
}
.product-detail button:hover {
    background-color: #fd8d27;
}
.product-detail h2 {
    margin: 0;
    font-size: 2.4rem;
    color: #fd8d27;
}
/* @media screen and (max-width: 1367px) {
    .sub-menu,
    .navbar-wrapper {
        padding: 1.3rem 5rem;
    }
} */
@media screen and (max-width: 1166px) {
    .navbar-wrapper {
        padding: 1rem;
    }
}
@media screen and (max-width: 700px) {
    .navbar-Container {
        display: none;
    }
    /* .navbar-wrapper {
        flex-wrap: wrap;
        gap: 1rem;
    }
    .navbar {
        gap: 0.5rem;
    }
    .navbar a {
        font-size: 0.8rem;
    }
    .navbar-wrapper p {
        display: none;
    } */
}
/* @media screen and (max-width: 360px) {
    .navbar {
        gap: 0.3rem;
    }
    .navbar a {
        font-size: 0.7rem;
    }
} */
