.refer-wrapper {
    display: grid;
    gap: 0.5rem;
    margin: 0 1rem;
    padding: 0rem 1rem;
    background: #ffffff;
}
.refer-wrapper > h3 {
    /* font-family: "Jost"; */
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 72px;
    letter-spacing: 0.01em;
    font-family: "Source Sans Pro", sans-serif;
    color: #103178;
}
.refer-wrapper img {
    justify-self: center;
    max-width: 400px;
    width: 100%;
}
.refer-wrapper p {
    justify-self: center;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem;
    margin: 0;
    text-align: center;
    letter-spacing: 0.02em;

    color: #474070;
}
.refer-code {
    justify-self: center;
    border: 1px solid #f58634;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    width: 150px;
    align-items: center;
    position: relative;
}

.refer-code button {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    width: fit-content;
    font-size: 2rem;
    background-color: inherit;
    border: none;
    cursor: pointer;
}
.refer-code button:before {
    content: "";
    display: none;
    position: absolute;
    z-index: 9998;
    top: 35px;
    left: 15px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(0, 0, 0, 0.72);
}
.refer-code button:after {
    content: "Copy to Clipboard";
    display: none;
    position: absolute;
    z-index: 9999;
    top: 40px;
    left: -37px;
    width: 114px;
    height: 36px;
    color: #fff;
    font-size: 10px;
    line-height: 36px;
    text-align: center;
    background: rgba(0, 0, 0, 0.72);
    border-radius: 3px;
}
.refer-code button:hover {
    background-color: #eee;
}
.refer-code button:hover:before,
.refer-code button:hover:after {
    display: block;
}
.refer-code button:active,
.refer-code button:focus {
    outline: none;
}
.refer-code button:active:after,
.refer-code button:focus:after {
    content: "Copied!";
}

.refer-code p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    letter-spacing: 0.01em;
    font-weight: bold;
    color: #103178;
}
.sharebuttons {
    justify-self: center;
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    padding: 0 0 1.5rem 0;
}
.sharebuttons button {
    border: none;
    border-radius: 50%;
    font-size: 1.4rem;
    display: flex;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    justify-content: center;
    cursor: pointer;
}
.whatsapp {
    background-color: #23b33a;
}
.fb {
    background-color: blue;
}
.telegram {
    background-color: lightskyblue;
}
.sharebuttons button img {
    width: 70px !important;
    overflow: hidden;
}
@media screen and (max-width: 767px) {
    .refer-wrapper {
        margin: 0 0.5rem;
    }
}
