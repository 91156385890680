.popularProducts-wrapper {
    padding: 2rem 7rem;
    display: grid;
    gap: 2rem;
}
.headings {
    /* position: relative; */
    display: grid;
}
.headings > button {
    justify-self: right;
    margin-right: 1rem;
    color: #103178;
    background-color: white;
    border: none;
    cursor: pointer;
    font-size: 1.1rem;
    font-family: "Source Sans Pro", sans-serif;
    /* font-weight: 550; */
    /* text-decoration: underline; */
}
.popularProducts-wrapper h2 {
    text-align: center;
    color: #103178;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 2rem;
}
.product-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    justify-items: center;
}
.popular-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    padding: 2rem 0;
    background-color: #f0f2f5;
    width: 100%;
    cursor: pointer;
}

.headings h2 {
    justify-self: center;
}
.headings button {
    width: fit-content;
    justify-self: right;
}
.popular-item img {
    width: 100px;
}
.popular-item h3,
.popular-item-two h3 {
    color: #103178;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.1rem;
}
.more-btn {
    cursor: pointer;
    color: #103178;
    background-color: white;
    width: fit-content;
    padding: 0.4rem 1.5rem;
    border-radius: 1rem;
    border: none;
    outline: none;
    transition: 0.6s;
    font-size: 0.9rem;
    letter-spacing: 1px;
    margin: 0;
    z-index: 1;
}
.more-btn:hover {
    background-color: #103178;
    color: white;
}
.popular-item-two {
    display: grid;
    /* gap: 1rem; */
    background-color: #f0f2f5;
    /* max-width: 200px; */
    width: 100%;
}
.popular-item-two img {
    align-self: center;
    justify-self: center;
}
.popular-item-two > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    padding: 1.5rem 0 0 2rem;
    justify-content: flex-end;
}
@media screen and (max-width: 1166px) {
    .popularProducts-wrapper {
        padding: 1rem;
    }
}
@media screen and (max-width: 767px) {
    .product-wrapper {
        gap: 1rem;
        justify-items: center;
        align-items: center;
    }
    .headings {
        gap: 1.5rem;
    }
    .headings h2 {
        font-size: 19px;
    }
}
