.notfound-Container {
    display: grid;
    align-items: center;
    justify-items: center;
}
.notfound-Container img {
    max-width: 55rem;
    width: 100%;
    height: 90%;
    max-height: 30rem;
}
.notfound-Container div {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
}
.notfound-Container button {
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    background-color: rgba(152, 152, 10, 1);
    padding: 0.5rem 2rem;
    color: white;
    border: 1px solid rgba(152, 152, 10, 1);
    cursor: pointer;
    font-size: 1.1rem;
}
.notfound-Container button:hover {
    color: black;
    background-color: white;
    border: 1px solid black;
}
