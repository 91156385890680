* {
    margin: 0;
    padding: 0;
}
.alice-carousel__prev-btn .alice-carousel__prev-btn-item {
    position: absolute;
    left: -1.4rem;
    top: 9rem;
    font-size: 2.5rem;
    color: white;
    border-radius: 50%;
    width: 2rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0 0 2rem 0; */
    padding: 0 0.5rem 0.5rem 0.3rem;
    background-color: #103178;
    /* opacity: 0; */
    transition: 0.6s;
    /* visibility: hidden; */
}
.alice-carousel__next-btn .alice-carousel__next-btn-item {
    position: absolute;
    right: -0.6rem;
    top: 9rem;
    font-size: 2.5rem;
    color: white;
    background-color: #103178;
    border-radius: 50%;
    width: 2rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0 0 2rem 0; */
    padding: 0 0.3rem 0.5rem 0.5rem;
    /* visibility: hidden; */
    transition: 0.6s;
}

.alice-carousel__prev-btn .alice-carousel__prev-btn-item:hover,
.alice-carousel__next-btn .alice-carousel__next-btn-item:hover {
    background-color: #ff9923;
}
.alice-carousel:hover .alice-carousel__next-btn .alice-carousel__next-btn-item,
.alice-carousel:hover .alice-carousel__prev-btn .alice-carousel__prev-btn-item {
    visibility: visible;
}

/* ------------------banners---------------------- */
.banner-slider .alice-carousel__prev-btn .alice-carousel__prev-btn-item,
.banner-slider .alice-carousel__next-btn .alice-carousel__next-btn-item {
    display: none;
}
/* ----------------react-paginate--------------- */
.paginationBttns {
    width: 85%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
}
.paginationBttns a {
    font-size: 0.9rem;
    padding: 10px;
    margin: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 0.8rem;
    height: 0.8rem;
    border-radius: 50%;
    /* border: 1px solid#2b2eff; */
    color: #103178;
    cursor: pointer;
}

.paginationActive a {
    color: white;
    background-color: #103178;
}
.paginationDisabled {
    color: grey;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

@media screen and (min-width: 800px) {
    .multi-slider .alice-carousel__stage li {
        max-width: 17rem;
    }
}
@media screen and (min-width: 1000px) {
    .multi-slider.alice-carousel__stage-item,
    .multi-slider .alice-carousel__stage-item {
        max-width: 17rem !important;
        width: 100%;
    }

    /* li.alice-carousel__stage-item {
        max-width: 15.5rem;
        margin-right: 01rem;
    } */
}
