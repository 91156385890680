.order-details-wrapper {
    display: grid;
    gap: 2rem;
    margin: 1rem 2rem;
}
.order-placed,
.order-status,
.order-items {
    display: grid;
    gap: 1rem;
    background: #ffffff;
    padding: 1.2rem 3rem;
    border-radius: 10px;
}
.order-placed p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-size: 1.3rem;
    /* line-height: 2.3rem; */
    letter-spacing: 0.01em;

    color: #5c557d;
}
.order-placed p:nth-child(1) {
    font-weight: 400;
}
.order-placed p:nth-child(1) span {
    color: #103178;
    font-weight: 600;
}
.order-placed p:nth-child(2) {
    font-weight: bold;
}
.order-placed p:nth-child(2) span {
    color: #f58634;
}

.order-status h3,
.order-items h3,
.delivery-address h3 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;

    font-size: 1.4rem;
    letter-spacing: 0.01em;
    color: #103178;
    font-weight: 600;
}
.title {
    font-size: 1rem;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
}
.order-item {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 300px));
    gap: 0rem;
    row-gap: 1rem;
}
.order-item img {
    width: 260px;
}
.order-desc {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.order-desc h3 {
    font-size: 1.1rem;
    font-weight: 600;
}
.order-desc h3 span {
    font-size: 1rem;
    color: #5c557d;
}
.order-desc p {
    color: #5c557d;
    font-family: "Roboto", sans-serif;
}
.order-desc p span {
    color: green;
}
.delivery-address {
    display: grid;
    gap: 1rem;
    background: #ffffff;
    padding: 1.2rem 3rem;
    border-radius: 10px;
}

.delivery-address p {
    color: #103178;
    font-size: 1.2rem;
    font-family: "Roboto", sans-serif;
}
.delivery-address p:nth-child(3) {
    max-width: 260px;
}
.price {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 500px;
    width: 100%;
    /* gap: 10rem; */
    align-items: center;
}
.price p:nth-child(2) {
    justify-self: end;
}
.total {
    padding-top: 1rem;
    border-top: 2px solid #103178;
}
.total p:first-child {
    font-weight: 700;
}
.order-btns {
    justify-self: center;
    display: flex;
    align-items: center;
    gap: 1rem;
}
.order-btns button {
    padding: 0.5rem 0;
    font-size: 1.1rem;
    width: 13rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
}
.order-btns button:first-child {
    color: #103178;
    background-color: inherit;
    border: 1px solid #103178;
}
.order-btns button:first-child:hover {
    color: white;
    background-color: #103178;
}
.order-btns button:nth-child(2) {
    color: red;
    background-color: inherit;
    border: 1px solid red;
}
.order-btns button:nth-child(2):hover {
    color: white;
    background-color: red;
}
@media screen and (max-width: 767px) {
    .order-placed,
    .order-status,
    .order-items,
    .delivery-address {
        gap: 0.5rem;
        padding: 1rem;
    }
    .order-status h3,
    .order-items h3,
    .delivery-address h3 {
        font-size: 1.5rem;
    }
    .order-placed p {
        font-size: 0.9rem !important;
    }
    .title {
        font-size: 0.6rem;
    }
}
@media screen and (max-width: 500px) {
    .order-details-wrapper {
        margin: 1rem;
    }
    .order-item {
        grid-template-columns: 1fr;
    }
    .order-item img {
        width: 90%;
    }
    .order-item h3 {
        font-size: 1rem !important;
    }
    .order-item p {
        font-size: 0.8rem !important;
    }
    .price p,
    .delivery-address p {
        font-size: 1rem;
    }
    .order-btns {
        display: grid;
    }
    .order-status h3,
    .order-items h3,
    .delivery-address h3 {
        font-size: 1rem;
    }
}
/* ------------cancelled modal---------- */
.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
.modal-content {
    display: grid;
    /* justify-items: center; */
    gap: 2rem;
    opacity: 1;
    max-width: 600px;
    width: 100%;
    padding: 2rem 0;
    background: #ffffff;
    border-radius: 14px;
    position: relative;
}
.modal-content .close-btn {
    position: absolute;
    right: 3px;
    border-top-right-radius: 14px;
    top: -32px;
    border: none;
    color: #103178;
    background-color: white;
}
.modal-content p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 53px;
    text-align: center;

    color: #1f0f34;
}
.control-btns {
    display: flex;
    justify-content: center;
    gap: 1rem;
}
.control-btns button {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 23px;
    text-align: center;
    padding: 0.6rem 7rem;
    border-radius: 14px;
    cursor: pointer;
}
.control-btns .cancel-btn {
    background: #ffffff;
    color: #103178;
    border: 1px solid #103178;
}

.control-btns .cancel-btn:active {
    background-color: #f5f5f5;
    /* border: none; */
}
.control-btns .delete-btn {
    border: none;
    background: red;
    color: #ffffff;
}
.form-control {
    display: grid;
    gap: 0rem;
    margin-bottom: 1rem;
    justify-items: center;
}
.form-control label {
    margin-bottom: 1rem;
    color: #24262d;
    font-size: 1.4rem;
}
.error {
    color: rgba(249, 128, 128, 1) !important;
    font-size: 1.1rem !important;
    font-weight: inherit !important;
    margin: 0;
}
.form-control textarea {
    resize: none;
    width: 70%;
    text-indent: 1rem;
    padding: 0.5rem 1rem;
    outline: none;
    color: black;
    font-family: "Roboto", sans-serif;
    font-size: 1.1rem;
}
.re-order {
    display: flex;
    /* align-items: center;
    justify-content: center;
    justify-self: right; */
    gap: 0.5rem;
    flex-wrap: wrap;
}
.re-order button,
.order-details-back > button {
    cursor: pointer;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
    color: #103178;
    background-color: inherit;
    border: 1px solid #103178;
    /* padding: 0.5rem 0; */
    font-size: 1.2rem;
    width: 10rem;
    height: 2.3rem;
}
.order-details-back > button {
    width: 5rem;
}
.re-order button:hover,
.order-details-back > button:hover {
    background-color: #103178;
    color: white;
}
.order-details-back {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
@media screen and (max-width: 790px) {
    .order-details-back > button {
        display: none;
    }
    .control-btns button {
        padding: 0.5rem 4rem;
    }
    .emptyCart-wrapper {
        padding: 1rem;
    }
}
@media screen and (max-width: 800px) {
    .modal-content {
        gap: 1rem;
        margin: 1rem;
        height: fit-content;
        padding: 0.1rem;
    }
    .modal-content .close-btn {
        top: -15px;
    }
    .modal-content p {
        font-size: 18px;
    }
    .control-btns button {
        font-size: 14px;
        padding: 0.5rem 2rem;
    }
}
.cancel-msg {
    font-size: 1rem !important;
    line-height: 2rem !important;
    justify-self: center;
    padding: 0 2rem;
    margin: 0 0 1rem 0 !important;
    color: red !important;
}
.stepper a {
    line-height: 32px !important;
}
