.points-wrapper {
    display: grid;
    gap: 0;
    margin: 0rem 2rem;
}
.points-wrapper header {
    height: 13rem;
    color: white;
    background: rgb(245, 124, 56);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: linear-gradient(
        180deg,
        rgba(245, 124, 56, 1) 0%,
        rgba(238, 104, 97, 1) 48.23%,
        rgba(212, 93, 123, 1) 100%
    );
}
.points-wrapper header h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 2.5rem;
    color: #ffffff;
}
.points-wrapper header h3 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 3rem;
    color: #ffffff;
}
.points-wrapper header h3 span {
    font-weight: 400;
    font-size: 2rem;
}
.walletTransaction {
    display: grid;
    background-color: white;
}
.walletTransaction h3 {
    padding: 2rem 1rem 0 1rem;

    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    letter-spacing: 0.01em;

    color: #103178;
}
.transaction-data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
    border-bottom: 2px solid rgba(189, 201, 226, 0.4);
}
.transaction-desc {
    display: grid;
    gap: 0.5rem;
}
.transaction-desc p:first-child span {
    font-size: 2.5rem;
}
.transaction-desc p:first-child {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 650;
    font-size: 1.5rem;
    letter-spacing: 0.02em;
    color: #103178;
}
.transaction-desc p:nth-child(2) {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: #5c557d;
}
.points-earned {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    letter-spacing: 0.02em;

    color: #25a239;
}
.points-redeemed {
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.01rem;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5rem;
    letter-spacing: 0.02em;
    color: red;
}
.emptyCart h1 {
    padding: 1rem;
    font-size: 1.3rem;
    border-bottom: 1px solid #f1f3f6;

    font-family: "Source Sans Pro", sans-serif;
}
.emptyCart-wrapper {
    /* width: 500px; */
    padding: 1.3rem 9rem 0 9rem;
}
.emptyCart {
    background-color: white;
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 0 0 1rem 0;
    /* box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75); */
}
.emptyCart h1 {
    justify-self: left;
    border: none;
}
.emptyCart h2 {
    font-family: "Roboto", sans-serif;
    font-size: 1.25rem;
}
.emptyCart p {
    margin: 0.4rem 0 !important;
}
.emptyCart img {
    width: 300px;
}
.emptyCart button {
    margin: 0.6rem 0;
    background-color: #103178;
    color: white;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 0.5rem 2rem;
    font-family: "Roboto", sans-serif;
}
@media screen and (max-width: 797px) {
    .points-wrapper header {
        height: 10rem;
        padding: 0;
        margin: 0;
    }
    .points-wrapper header h2 {
        font-size: 1.5rem;
    }
    .points-wrapper header h3 {
        font-size: 2rem;
    }
    .emptyCart-wrapper {
        padding: 0rem;
    }
    .transaction-desc p:first-child,
    .transaction-desc p:nth-child(2),
    .points-earned,
    .points-redeemed {
        font-size: 1rem;
    }
    .transaction-data {
        padding: 0.3rem;
    }
    .points-wrapper {
        margin: 0 0.3rem;
    }
    .emptyCart img {
        width: 100%;
        max-width: 300px;
    }
    .walletTransaction h3 {
        font-size: 1.3rem;
    }
}
