.cart-container {
    background-color: #f1f3f6;
    height: 100%;
}
.cart {
    padding: 1.3rem 9rem 0rem 9rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 30%;
}

.cart-items {
    background-color: white;
}
.cart-items > h1,
.emptyCart h1 {
    padding: 1rem;
    font-size: 1.3rem;
    border-bottom: 1px solid #f1f3f6;

    font-family: "Source Sans Pro", sans-serif;
}

.cart-item {
    cursor: pointer;
    padding: 1rem;
    display: flex;
    gap: 2rem;
    border-bottom: 1px solid #f1f3f6;
}
.cart-item img {
    width: 200px;
    object-fit: cover;
}
.cart-item-desc {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.cart-item-desc h3 {
    font-family: "Source Sans Pro", sans-serif;
}
.cart-item-desc p {
    margin: 0 !important;
    font-family: "Roboto", sans-serif;
}
.company {
    color: rgb(155, 153, 153);
}
.cost {
    color: black;
}
.mrp {
    display: flex;
    gap: 1rem;
}
.mrp p {
    font-size: 0.9rem;
    margin: 0;
    color: black;
    font-weight: 550;
}
.mrp del {
    color: rgb(155, 153, 153) !important;
}
.save {
    color: green !important;
}
.add-items p {
    color: red;
    width: 100%;
    padding: 0.5rem 0;
    text-align: center;
    background-color: rgba(222, 56, 56, 0.2);
}
.add-del-quantity {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.cart-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    width: 40%;
}
.add-del-quantity button {
    cursor: pointer;
    font-size: 1.2rem;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    border: 1px solid #f1f3f6;
    font-family: "Roboto", sans-serif;
    font-weight: 900;
}
.remove-btn {
    justify-self: right;
    display: flex;
    justify-content: flex-end;
    flex-basis: 100%;
}
.remove-btn button {
    font-family: "Roboto", sans-serif;
    width: fit-content;
    height: fit-content;
    border: none;
    background-color: inherit;
    font-size: 1rem;
    cursor: pointer;
    font-weight: 600;
}
.cart-bill-wrapper {
    position: sticky;
    top: 195px;
    height: fit-content;
    display: grid;
    gap: 1.5rem;
}

.cart-bill,
.coupen {
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}
.coupen-applied {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
}
.coupen-applied p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: #1e144f;
}
.coupen-applied button {
    cursor: pointer;
    border: 1px solid #f58634;
    border-radius: 50%;
    background-color: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #f58634;
    width: 2rem;
    height: 2rem;
}
.coupem-applied img {
    width: 80px;
}
.cart-bill h1 {
    padding: 1rem;
    color: #878787;
    text-transform: uppercase;
    font-size: 1rem;
    font-family: "Source Sans Pro", sans-serif;
    border-bottom: 1px solid #f1f3f6;
}
.bill-data {
    padding: 0 1rem;
    display: flex;
    justify-content: space-between;
}
.bill-data p {
    font-weight: 550;
    font-size: 1.1rem;
    font-family: "Roboto", sans-serif;
    font-weight: 600;
}
/* .bill-data p:nth-child(2) {
   } */
.cart-bill > p {
    font-size: 1.1rem;
    padding: 0 1rem;
    font-family: "Roboto", sans-serif;
    color: green;
}
.total {
    padding: 0.5rem;
    border-top: 1px dashed #878787;
    border-bottom: 1px dashed #878787;
}
.total p:nth-child(1) {
    font-weight: 750;
}

.coupen h1 {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.3rem;
    /* font-weight: bold; */
    font-weight: 530 !important;
}
.coupen form {
    width: 100%;
}
.coupen form {
    display: flex;
}
.coupen input {
    width: 15rem;
    height: 2rem;
    font-size: 0.9rem;
    font-family: "Roboto", sans-serif;
    outline: #b09975;
    border: 1px solid #f1f3f6;
    text-indent: 1rem;
}
.coupen button {
    padding: 0 1rem;
    outline: none;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
}
.order-button {
    padding: 0.6rem 0;
    font-size: 1.2rem;
    background-color: #d5a249;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
}
.emptyCart-wrapper {
    /* width: 500px; */
    padding: 1.3rem 9rem 0 9rem;
}
.emptyCart {
    background-color: white;
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 0 0 1rem 0;
    /* box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75); */
}
.emptyCart h1 {
    justify-self: left;
    border: none;
}
.emptyCart h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.3rem;
}
.emptyCart p {
    margin: 0.4rem 0 !important;
    font-family: "Roboto", sans-serif;
}
.emptyCart img {
    width: 300px;
}
.emptyCart button {
    margin: 0.6rem 0;
    background-color: #103178;
    color: white;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 0.5rem 2rem;
    font-family: "Roboto", sans-serif;
}
@media screen and (max-width: 1166px) {
    .cart {
        padding: 0rem;
        grid-template-columns: 1fr;
    }
    .cart-item {
        display: grid;
        padding: 0.4rem;
        grid-template-columns: 150px 1fr;
        gap: 1rem;
    }
    .cart-item img {
        justify-self: center;
        align-self: center;
        width: 150px;
        /* height: 80%; */
    }
    .remove-btn {
        grid-column: 1/3;
        justify-self: unset;
        align-self: unset;
        background-color: #f1f3f6;
        color: red;
        justify-content: center;
        padding: 0.5rem 0;
    }
}
@media screen and (max-width: 767px) {
    .cart-item-desc h3 {
        font-size: 1rem;
    }
}
/* ----------delete modal ------- */
.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}
.modal-blur {
    /* background-color: rgba(255, 255, 255, 0.4); */
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
}
.modal-blur select {
    height: 3rem;
    width: 15rem;
    text-indent: 0.5rem;
    font-size: 1.1rem;
    font-family: "Roboto", sans-serif;
    font-weight: 550;
}
.modal-content {
    display: grid;
    justify-items: center;
    gap: 2rem;
    opacity: 1;
    max-width: 500px;
    width: 100%;
    padding: 1rem 0;
    background: #ffffff;
    border-radius: 14px;
    position: relative;
}

.modal-content .close-btn {
    position: absolute;
    right: 3px;
    border-top-right-radius: 14px;
    top: -32px;
    border: none;
    color: #103178;
    background-color: white;
}
.modal-content p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    /* line-height: 53px; */
    text-align: center;

    color: #1f0f34;
}
.control-btns {
    display: flex;
    gap: 1rem;
}
.control-btns button {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    width: 7rem;
    height: 2.5rem;
    border-radius: 14px;
    cursor: pointer;
}
.control-btns .cancel-btn {
    background: #ffffff;
    color: #103178;
    border: 1px solid #103178;
}
.control-btns .delete-btn:active {
    background-color: #103178;
}
.control-btns .cancel-btn:active {
    background-color: #f5f5f5;
    /* border: none; */
}
.control-btns .delete-btn {
    border: none;
    background: #103178;
    color: #ffffff;
}
@media screen and (max-width: 790px) {
    .control-btns button {
        padding: 0.5rem 4rem;
    }
    .emptyCart-wrapper {
        padding: 1rem;
    }
}
@media screen and (max-width: 800px) {
    .modal-content,
    .modal-content-pay {
        gap: 1rem;
        margin: 1rem;
        height: fit-content;
        padding: 0.1rem;
    }
    .modal-content .close-btn {
        top: -15px;
    }
    .modal-content p {
        font-size: 18px;
    }
    .control-btns button {
        font-size: 14px;
        padding: 0.5rem 2rem;
    }
}
/* -----------paymnet modalcontent----------- */

.modal-content-pay {
    padding: 1rem;
    gap: 1rem;
    max-width: 400px;
    display: grid;
    opacity: 1;
    width: 100%;
    background: #ffffff;
    border-radius: 14px;
    position: relative;
}
.modal-content-pay h3 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    /* identical to box height */

    letter-spacing: 0.01em;

    color: #1e144f;
}
.pay-form {
    font-family: "Source Sans Pro", sans-serif;
    color: #1e144f;
    padding: 0 1rem;
    font-size: 25px;
    display: grid;
    gap: 1rem;
}
.pay-form form {
    display: grid;
    gap: 0.5rem;
}
.pay-form form .pay-control {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 0.3rem;
}
.pay-control label {
    cursor: pointer;
    font-size: 1.2rem;
}
.pay-control input {
    cursor: pointer;
}
input[type="radio"]:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}

input[type="radio"]:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #ffa500;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 2px solid white;
}
.pay-form form button {
    color: white;
    font-size: 1.1rem;
    width: fit-content;
    justify-self: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
    border: none;
    background-color: #103178;
    font-family: "Roboto", sans-serif;
}
.payLalbel p {
    margin: 0 0 0 1rem !important;
    font-family: "Roboto", sans-serif;
}
.delivery-data {
    display: grid;
    gap: 0.2rem;
}
.deliveryError {
    font-family: "Roboto", sans-serif;
    font-weight: 600;
}
.delivery-data h3 {
    font-size: 1rem;
    font-weight: 550;
    font-family: "Source Sans Pro", sans-serif;
}
.delivery-data p {
    font-size: 1rem;
    font-family: "Roboto", sans-serif;
    font-weight: 550;
}
.delivery-address-selected {
    display: grid;
    gap: 0.3rem;
}
.delivery-address-selected p {
    margin: 0;
    width: 100%;
    max-width: 20rem;
    font-family: "Roboto", sans-serif;
}

.address-select-btn {
    padding: 0.5rem 0rem !important;
    cursor: pointer;
    border: none;
    background-color: #103178;
    color: white;
    font-size: 1rem;
}
.out-of-stock {
    color: red;
    width: 100%;
    padding: 0.5rem 0;
    text-align: center;
    background-color: rgba(222, 56, 56, 0.2);
}
/* 
*/
