.delivery {
    margin: 2rem 7rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: #f8f9fa;
    align-items: center;
}
.delivery .content {
    padding: 1.4rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Source Sans Pro", sans-serif;
    color: #103178;
    gap: 0.4rem;
    font-size: 1.3rem;
    border-right: 1px solid lightgrey;
}
.footer-container {
    padding: 2rem 7rem;
    padding-right: 1rem;
    display: grid;
    grid-template-columns: 20% 32% 35%;
    gap: 3rem;
}
.address {
    /* border-right: 1px solid #5b6c8f; */
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.footer-container p {
    color: #5b6c8f;
    font-family: "Roboto", sans-serif;
    font-weight: 550 !important;
}
.need-help {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
}
.need-help h3,
.need-help h1 {
    color: #103178;
    font-size: 1.3rem;
    font-family: "Source Sans Pro", sans-serif;
}
.need-help h1 {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
}
.phone {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #5b6c8f;
}
.phone p {
    font-family: "Roboto", sans-serif;
    font-size: 1.3rem;
    color: #103178;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: 600 !important;
}
.number {
    margin-bottom: 1rem !important;
}
.timings {
    color: #5b6c8f !important;
    font-size: 1.1rem !important;
}
.products-col {
    justify-self: flex-start;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.3rem;
}
.products-col button {
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    transition: 0.5rem;
    font-size: 1rem;
    font-weight: 550;
    transition: 0.5s;
    background-color: inherit;
    border: none;
    color: #103178;
    cursor: pointer;
}
.products-col a {
    color: #103178;
    font-size: 1.3rem;

    font-family: "Roboto", sans-serif;
    font-weight: bold;
    text-decoration: none;
    transition: 0.5rem;
    font-size: 1rem;
    font-weight: 550;
    transition: 0.5s;
}
.products-col a:hover {
    color: #fd8d27;
}
.products-col h3 {
    /* padding-bottom: 0.5rem; */
    color: #103178;
    font-size: 1.2rem;
    font-weight: 650;
    font-family: "Source Sans Pro", sans-serif;
}
.links {
    display: flex;
    gap: 2rem;
    justify-content: flex-start;
    width: 100%;
    /* gap: 1rem; */
}
.social-links {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.copyright {
    border-top: 1px solid #f7f7f7;
}
.copyright div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem 2rem;
    /* cursor: pointer; */
}
.copyright p {
    font-family: "Roboto", sans-serif;
    color: #5b6c8f;
}
@media screen and (max-width: 1166px) {
    .footer-container {
        padding: 1rem !important;
        overflow: hidden;
    }
}
@media screen and (max-width: 1100px) {
    .footer-container {
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        gap: 3rem;
    }
    .phone {
        border-bottom: none;
    }
    .delivery {
        grid-template-columns: 1fr;
    }
    .delivery .content {
        border-right: 0;
        border-bottom: 1px solid lightgrey;
    }
}
@media screen and (max-width: 767px) {
    .footer-container {
        padding: 1rem !important;
        overflow: hidden;
    }
    .products-col h3 {
        font-size: 0.9rem;
    }
    .products-col a,
    .products-col button {
        font-size: 0.8rem;
    }

    .delivery {
        margin: 1rem;
    }
    .copyright div {
        margin: 0.5rem 1rem;
    }
    .copyright {
        padding-bottom: 1.5rem;
    }
    .copyright p {
        font-size: 0.5rem;
    }
}
@media screen and (max-width: 500px) {
    .links {
        justify-content: flex-start;
        gap: 2rem;
    }
}
