.heading {
    text-align: center;
    margin: 0.8rem 0;
    font-family: "Source Sans Pro", sans-serif;

    font-size: 1.6rem;
}
.wishlist-wrapper {
    padding: 1.3rem 9rem 0rem 9rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 1rem;
}
.wishlist-item {
    cursor: pointer;
    display: grid;
    gap: 0.5rem;
}
.image {
    position: relative;
}
.image > img {
    width: 100%;
    height: 200px;
}
.image > div {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: grid;
    align-items: center;
    justify-items: center;
}
.image p {
    color: red;
    font-size: 1.2rem;
    text-transform: uppercase;
    transform: rotate(-35deg);
}
.add-to-bag {
    position: absolute;
    top: 160px;
    left: 10px;
    border: none;
    background-color: inherit;
    cursor: pointer;
}
.item-desc {
    display: grid;
    justify-items: center;
    gap: 0.3rem;
    text-align: center;
}
.item-desc p {
    font-family: "Roboto", sans-serif;
}
.item-desc > p:nth-child(1) {
    font-size: 1.1rem;
    color: #b19955;
    font-weight: bold;
}

.mrp {
    display: flex;
    gap: 0.5rem;
}
.mrp p:nth-child(2) {
    display: flex;
    align-items: center;
}
.mrp p:nth-child(1) {
    font-weight: bold;
}

.mrp p span {
    color: #b19955;
    font-size: 0.7rem;
}
.delete-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    font-size: 1.2rem;
    color: grey;
    cursor: pointer;
}
.emptyCart-wrapper {
    /* width: 500px; */
    padding: 1.3rem 9rem 0 9rem;
}
.emptyCart {
    background-color: white;
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 0 0 1rem 0;
    /* box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75); */
}
.emptyCart h1 {
    justify-self: left;
    border: none;
}
.emptyCart h2 {
    font-family: "Source Sans Pro", sans-serif;
    letter-spacing: 0.01rem;
    font-size: 1.3rem;
}
.emptyCart p {
    margin: 0.4rem 0 !important;
}
.emptyCart img {
    width: 300px;
}
.emptyCart button {
    margin: 0.6rem 0;
    background-color: #103178;
    color: white;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 0.5rem 2rem;
    font-family: "Roboto", sans-serif;
}
@media screen and (max-width: 1166px) {
    .wishlist-wrapper {
        padding: 1rem;
    }
}
@media screen and (max-width: 600px) {
    .wishlist-wrapper {
        justify-items: center;
        grid-template-columns: 1fr;
    }
    .emptyCart-wrapper {
        padding: 1rem;
    }
}
