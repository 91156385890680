.help-wrapper {
    display: grid;
    gap: 2rem;
}
.navbar {
    padding: 0 3rem;
    display: flex;
    gap: 0;
    align-items: center;
}
.form-control select {
    width: 100%;
    height: 2.6rem;
    font-size: 1rem;
    text-indent: 1rem;
    border-radius: 10px;
    background-color: rgba(229, 231, 235, 0.6);
    outline: none;

    border: 1px solid rgba(229, 231, 235, 1);
}
.navbar button {
    font-size: 1rem;
    padding: 0.5rem 2rem;
    cursor: pointer;
    transition: 0.3s;
    font-family: "Source Sans Pro", sans-serif;
}
.active-form {
    border: 1px solid #103178;
    color: white;
    background-color: #103178;
}
.inactive-form {
    background-color: white;
    border: 1px solid lightgray;
}
/* ----------forms----------------- */
.help-form {
    padding: 1rem;
    display: grid;
    justify-items: center;
}
.help-form form {
    background-color: white;
    border-radius: 10px;
    width: 70%;
    display: grid;
    justify-items: center;
    gap: 1rem;
    padding: 1rem 1rem 2rem 3rem;
}
.help-form h3 {
    text-align: center;

    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    letter-spacing: 0.01em;
    color: #103178;
    margin-bottom: 2rem;
}
.form-control,
.item-image-upload {
    align-items: center;
    display: grid;
    margin: 0 2rem;
    width: 70%;
    gap: 1rem;
}
.upload-image {
    cursor: pointer;
}

.uploaded-data {
    border: 1px dashed grey;
    padding: 1rem;
    /* width: 65%; */
}
.error {
    color: red;
    margin-top: 0.5rem;
    text-transform: capitalize;
}
.form-control label,
.item-image-upload label {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 26px;
    text-transform: uppercase;
    letter-spacing: 0.02em;
    color: #103178;
}
.form-control input,
.form-control textarea {
    height: 2.6rem;
    width: 100%;
    /* max-width: 25rem; */

    font-size: 1rem;
    text-indent: 1rem;
    border-radius: 10px;
    background-color: rgba(229, 231, 235, 0.6);
    outline: none;
    border: 1px solid rgba(229, 231, 235, 1);
}
.form-control textarea {
    height: unset;
    resize: none;
    font-family: "Roboto", sans-serif;
}
.help-form input[type="submit"] {
    justify-self: center;
    padding: 0.4rem 3rem;
    height: 3rem;
    background-color: rgba(16, 49, 120, 1);
    color: white;
    font-family: inherit;
    border: none;
    border-radius: 10px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: 500ms;
}
.add-item {
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 0.5rem;
    width: 100%;
}
.add-item .upload-icon {
    color: #0e9f6e;
    font-size: 2rem;
}
.add-item h5 {
    font-size: 14px;
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
}
.add-item p {
    color: #9e9e9e;
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
}
@media screen and (max-width: 950px) {
    .help-wrapper {
        padding: 0;
        margin: 0;
    }
    .help-form form {
        width: 90%;
    }
    .navbar button {
        padding: 1rem;
        font-size: 0.9rem;
    }
    .help-form .form-control {
        width: 100%;
    }
    .help-form .item-image-upload {
        width: 100%;
    }
}
@media screen and (max-width: 767px) {
    .help-form form {
        padding: 1.5rem;
    }
    .form-control,
    .item-image-upload {
        margin: 0;
    }
}
@media screen and (max-width: 500px) {
    .navbar {
        padding: 0.3rem;
    }
    .navbar button {
        padding: 0.5rem;
        font-size: 0.8rem;
    }
}
/* ---------------edir profile------------- */

.imageupload {
    position: relative;
    justify-self: center;
}

.profilepic_icon {
    position: absolute;
    bottom: 0;
    right: 1rem;
    color: white;
    background-color: #0c5ada;
    padding: 0.5rem;
    display: grid;
    align-items: center;
    justify-items: center;
    cursor: pointer;
    border-radius: 10px;
}
.imageupload img {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
}
.imageupload > input {
    display: none;
}
.uploaded-name {
    position: relative;
    width: fit-content;
}
.uploaded-name button {
    position: absolute;
    right: 0;
    background-color: red;
    color: white !important;
    border-radius: 50%;
    border: none;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    font-size: 1.4rem;
}
.image-deleteIcon {
    color: white !important;
}
.uploaded-img {
    display: grid;
    row-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}
.uploadfile {
    border: 1px dashed grey;
    padding: 1rem;
    width: 94%;
}
