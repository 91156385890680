.policy-wrapper {
    margin: 4rem 9rem;
    display: grid;
    gap: 1rem;
}
.policy-wrapper > h1 {
    text-align: center;
    font-size: 1.6rem;
    font-family: "Source Sans Pro", sans-serif;
}

.policy {
    display: grid;
    gap: 1rem;
    font-family: "Source Sans Pro", sans-serif;
}

.policy h2,
.header {
    font-size: 1.3rem;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 500;
}

.policy p {
    font-family: "Roboto", sans-serif;
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-align: justify;
    margin: 0;
}
.policy p span {
    font-weight: 300;
}
.points-header {
    text-decoration: underline;
}
.policy ul,
.policy ol {
    margin: 0.5rem 0 0 1rem;
}

.policy li {
    margin-top: 0.3rem;
    text-align: justify;
}
.policy table {
    border: 1px solid black;
    width: 70%;
    padding: 1rem;
    border-collapse: collapse;
}
.shippingcharges {
    width: 40% !important;
}
.policy table thead tr th {
    text-align: left;
    vertical-align: middle;
    padding: 0.3rem 0.5rem;
    border-right: 1px solid black;
}
.policy table tr {
    border: none;
    border-bottom: 1px solid black !important;
}
.policy table tr td {
    border-right: 1px solid black;
    /* padding: 1rem; */
}
.policy table tbody tr td {
    padding: 0.5rem 1rem;
}
.policy-dislaimer p {
    color: grey;
    font-size: 1.2rem;
    font-family: "Roboto", sans-serif;
}
@media screen and (max-width: 767px) {
    .policy-wrapper {
        margin: 2rem;
    }
    .policy h2 {
        font-size: 1rem;
    }
    .policy p {
        font-size: 0.9rem;
    }
}
