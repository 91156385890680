.products-wrapper {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    font-size: 1rem;
    gap: 1rem;
}
.product-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 3rem;
    height: 100%;
}
