/* --------loader--------- */
.circle-loader {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    animation: linear normal forwards;
    animation-name: run;
    animation-duration: 100ms;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transition: 100ms;
}
.loader {
    /* margin-left: 15rem; */
    display: grid;
    align-items: center;
    justify-items: center;
}

/* ---------------error---------------------- */
.modal {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);

    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000;
}
.modal-content {
    display: grid;
    /* justify-items: center; */
    gap: 2rem;
    opacity: 1;
    max-width: 500px;
    width: 100%;
    /* padding: 0.5rem 0; */
    background: #ffffff;
    border-radius: 5px;
    position: relative;
}
.header {
    padding: 0.5rem 1rem 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #cecaca;
    background-color: #ff0000;
}
.header > div {
    display: flex;
    gap: 0.3rem;
    align-items: center;
}
.header p {
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
    color: white;
    font-family: "Source Sans Pro", sans-serif;
}
.header button {
    border: none;
    background-color: inherit;
}
.spinner {
    display: grid;
    /* justify-items: center; */
    gap: 2rem;
    opacity: 1;
    /* max-width: 600px; */
    justify-items: center;
    width: 100%;
    padding: 2rem 0;
    /* background: #ffffff; */
    border-radius: 14px;
    position: relative;
}
.modal-content form {
    display: grid;
    gap: 0.5rem;
    align-items: center;
}
.modal-content > p {
    color: #716d6d;
    font-family: sans-serif;
    font-weight: 500;
    margin-left: 2rem !important;
}
.control-btns {
    padding: 0.8rem 2rem 0.8rem 0;
    display: grid;
    gap: 1rem;
    justify-items: right;
    background-color: #cecaca;
}
.control-btns button {
    cursor: pointer;
    font-size: 1rem;
    padding: 0.3rem 0.5rem;
    display: flex;
    align-items: center;
    justify-items: center;
}
.submit-btn {
    background-color: blue;
    color: white;
    border: 1px solid blue;
}
.delete-btn {
    color: white;
    border: 1px solid #cecaca;
    background-color: red;
}
.close-icon {
    cursor: pointer;
}
@media screen and (max-width: 800px) {
    .modal-content {
        gap: 1rem;
        margin: 1rem;
        height: fit-content;
        padding: 0.1rem;
    }
}
@media screen and (max-width: 400px) {
    .modal-content {
        max-width: 100%;
    }
}
