.contact-container {
    background-color: #f6f7fb;
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
    height: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
}
.wrapper {
    /* width: 80%; */
    margin-top: 2rem;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
    background-color: white;
    padding: 2rem 1rem;
}
.wrapper-left {
    display: grid;
    gap: 1.5rem;
}
.call-us h4 {
    font-size: 1.2rem;
    font-weight: 550;
    margin-left: 0.5rem;
    font-family: "Source Sans Pro", sans-serif;
}
.call-us {
    padding: 1rem;
    box-shadow: 0 0 6px 0 rgb(0 0 0 / 16%);
    background-color: white;
    display: grid;
    gap: 1rem;
    border-radius: 1rem;
    width: 80%;
}
.call-us h3 {
    display: flex;
    align-items: center;
    font-size: 1.2rem;
    gap: 0.5rem;
    font-family: "Source Sans Pro", sans-serif;
}
.call-us > p {
    font-family: "Roboto", sans-serif;
    font-weight: 550;
}
.call-us div {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.call-us div p {
    font-family: "Roboto", sans-serif;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
.address,
.wrapper-right p {
    color: #858181;
    line-height: 1.4rem;
}
.corporate {
    font-weight: 550;
    font-size: 1.1rem;
}
.wrapper-right {
    display: grid;
    gap: 1rem;
    align-self: baseline;
    align-items: initial;
    justify-items: center;
}
.wrapper-right h3 {
    color: #239d97;
    font-family: "Source Sans Pro", sans-serif;
}
.wrapper-right p {
    text-align: center;
    font-family: "Roboto", sans-serif;
}
.wrapper-right img {
    margin-top: 3rem;
    width: 60%;
}
@media screen and (max-width: 767px) {
    .wrapper {
        grid-template-columns: 1fr;
        gap: 2rem;
        align-items: center;
        justify-items: center;
    }
    .wrapper-left {
        justify-items: center;
    }
    .wrapper-right {
        grid-row: 1/2;
    }
    .wrapper-right img {
        width: 70%;
    }
}
