.profile-wrapper {
    display: grid;
    grid-template-columns: 280px 1fr;
}
.profile-leftside {
    /* background-color: white !important; */
    height: 100%;
}
.profile-rightSide {
    padding-top: 2rem;
    background: #f6f7fb;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 100%;
    min-height: 72vh;
}
.orders-header {
    align-self: center;
    display: flex;
    gap: 2rem;
    background-color: inherit;
    /* flex-wrap: wrap; */
}
.orders-header button {
    font-size: 1.1rem;
    font-family: "Source Sans Pro", sans-serif;
    padding: 0.7rem 3rem;
    cursor: pointer;
    transition: 0.4s;
    /* font-weight: 550; */
}
.Order-btn-active {
    background-color: #f58634;
    border: 1px solid #f58634;
    color: white;
}
.Order-btn-inactive {
    background-color: inherit;
    border: 1px solid #5c557d;
}
.order-details-wrapper {
    margin: 0 5rem;
    padding: 1rem 2rem;
    display: grid;
    gap: 2rem;
}
.order-details {
    padding: 1rem 2rem;
    border: 1px solid #bdc9e2;
    display: grid;
    gap: 0.5rem;
    cursor: pointer;
    font-family: "Source Sans Pro", sans-serif;
}
.order-details:hover {
    background-color: #5f739e;
}
.order-details:hover * {
    color: white !important;
}
.order-details h3 {
    font-family: "Source Sans Pro", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0.01em;
    font-weight: bold;
    color: #103178;
}
.order-details P {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* line-height: 43px; */
    letter-spacing: 0.02em;

    color: #5c557d;
}
.emptyCart-wrapper {
    /* width: 500px; */
    padding: 1.3rem 9rem 0 9rem;
}
.emptyCart {
    background-color: white;
    display: grid;
    align-items: center;
    justify-items: center;
    padding: 0 0 1rem 0;
    /* box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.75); */
}
.emptyCart h1 {
    justify-self: left;
    border: none;
}
.emptyCart h2 {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.3rem;
}
.emptyCart p {
    margin: 0.4rem 0 !important;
}
.emptyCart img {
    width: 300px;
}
.emptyCart button {
    margin: 0.6rem 0;
    background-color: #103178;
    color: white;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;
    padding: 0.5rem 2rem;
}
@media screen and (max-width: 900px) {
    .order-details h3 {
        font-size: 1.6rem;
    }
    .order-details p {
        font-size: 1rem;
    }
}
@media screen and (max-width: 786px) {
    .profile-wrapper {
        grid-template-columns: 3.7rem 1fr;
    }
    .orders-header {
        gap: 1rem;
    }
    .orders-header button {
        padding: 1rem;
        font-size: 1rem;
    }
    .order-details-wrapper {
        margin: 0 1rem;
        padding: 0;
    }
    .emptyCart-wrapper {
        padding: 0rem;
    }
}
@media screen and (max-width: 360px) {
    .order-details h3 {
        font-size: 1.1rem;
    }
    .order-details p {
        font-size: 0.9rem;
    }
    .orders-header button {
        font-size: 0.9rem;
    }
}
