.links {
    display: flex;
    gap: 1rem;
    align-items: center;
}
.links a {
    text-decoration: none;
    font-size: 14px;
    font-family: "Source Sans Pro", sans-serif;
}
.links p {
    margin: 0;
    padding: 0;
}
.home-link {
    color: #5b6c8f;
}
.shop {
    color: #103178;
    font-weight: bold;
}
